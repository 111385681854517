import React from 'react'
import {useField} from '@formiz/core'

export const InputField = (props) => {
   const {setValue,value , errorMessage , isValid , isSubmitted , id } = useField(props)
   const { type, required , placeholder , isDefault = true } = props
   const [isTouched, setIsTouched] = React.useState(false)
   const showError = !isValid && (isTouched || isSubmitted)
  

   return (
       
    <div className="row">
      <div className="col-md-12">
        <div className="mb-3 input-group position-relative">
            {/* <label htmlFor={id} className="form-label">{label}</label> */}
            <input 
            //    name="projectName" 
               placeholder={placeholder || ''} 
               id={id} 
               type={type || 'text' }  
               className={`form-control ${(showError) ? 'is-invalid' : ''}`} 
               value={value ?? ''}
               onChange={e => setValue(e.target.value)}
               onBlur={() => setIsTouched(true)}
               aria-invalid={showError}
               aria-required={!!required}
               aria-describedby={showError ? `${id}-error` : null}
               style={{
                  background:  isDefault ? "#00000008" : "",
                }}
              />
              {
                type === 'text' && isDefault === true ? (
                  <i className="position-absolute translate-middle bottom-0  end-0 top-50">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.1593 4.90346C17.6361 4.47008 18.3642 4.47009 18.8409 4.90349C18.8596 4.92048 18.884 4.94459 18.9698 5.03033C19.0555 5.11606 19.0796 5.14047 19.0966 5.15917C19.53 5.63593 19.53 6.36399 19.0966 6.84075C19.0796 6.85945 19.0555 6.88385 18.9697 6.96959L18.1627 7.77665C17.0865 7.80091 16.1991 6.91356 16.2233 5.83742L17.0305 5.0303C17.1162 4.94456 17.1406 4.92046 17.1593 4.90346ZM14.9789 4.96058C14.9724 4.96681 14.966 4.97317 14.9598 4.97964L6.03657 13.9028L5.96302 13.9763C5.25466 14.6844 4.79718 15.1417 4.47843 15.7047C4.15967 16.2676 4.00291 16.8952 3.76019 17.8669L3.73498 17.9678L3.27241 19.8181C3.20851 20.0737 3.2834 20.344 3.46969 20.5303C3.65597 20.7166 3.92634 20.7915 4.18192 20.7276L6.03221 20.265L6.1331 20.2398C7.1048 19.9971 7.73236 19.8403 8.29534 19.5216C8.85831 19.2028 9.31561 18.7453 10.0237 18.037L10.0972 17.9635L19.0241 9.03652C19.028 9.03269 19.0319 9.02882 19.0357 9.02491L20.0304 8.03025L20.043 8.01763C20.1103 7.95033 20.1622 7.8985 20.2065 7.84971C21.16 6.80085 21.16 5.19912 20.2066 4.15023C20.1622 4.10145 20.1104 4.04963 20.0431 3.98235L20.043 3.98228L20.0305 3.96969L20.0179 3.95713L20.0179 3.95709C19.9506 3.88977 19.8987 3.83792 19.8499 3.79356C18.801 2.84007 17.1993 2.84005 16.1504 3.79351C16.1016 3.83787 16.0497 3.88972 15.9824 3.95705L15.9698 3.96963L14.9789 4.96058ZM16.8917 9.04759L9.03654 16.9028C8.22978 17.7095 7.92038 18.0101 7.55629 18.2163C7.1922 18.4224 6.77527 18.5331 5.6684 18.8098L5.03079 18.9692L5.19019 18.3316C5.46691 17.2247 5.57758 16.8078 5.78372 16.4437C5.98987 16.0796 6.29045 15.7702 7.09722 14.9634L14.9524 7.10832C15.2981 7.99364 16.0064 8.70195 16.8917 9.04759Z"
                      fill="black"
                      fillOpacity="0.56"
                    />
                  </svg>
                </i>
                ) : null
              }
              {showError && (
               <div id={`${id}-error`} className="invalid-feedback d-block">
                  { errorMessage }
               </div>
               )}
              
        </div>
      </div>
    </div>
                
        
   )
}