import React, { useState, useEffect } from "react"
import { Alert, Button, Card, CardHeader, CardBody, Col, Form, Label, Input, Row, Table, Modal } from "reactstrap";

import * as url from "../../helpers/jengasmart_api_urls";

import axios from 'axios';
import { map } from "lodash";

import {LoadingIndicator} from '../../components/Common'
import DeleteModal from "../../components/Common/DeleteModal";
import { useCompanyInventory } from '../../hooks/inventoryHook.js';
import { EquipmentModal } from "../../components/Modals/EquipmentModal";
import { useEquipment } from '../../hooks/equipmentHook.js';
import { EditequipmentModal } from "../../components/Modals/EditEquipmentModal";

const Equipment = (props) => {
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState(false);
  const [listError, setListError] = useState(false);
  const [equipmentName, setEquipmentName] = useState('');
  const [rate, setRate] = useState('');
  const [capitalInvestment, setCapitalInvestment] = useState(true);
  const [ratePrice, setRatePrice] = useState('');
  const [description, setDescription] = useState('');

  const [isSaving,setSaving] = useState(false)
  const [isShowDelete , setshowDelete] = useState(false)
  const [deleteItem,setDeleteItem] = useState("")
  // const [isDeleting,setDeleting] = useState(false)

  const [isEditing,setEditing] = useState(false)
  const [equipment,setEquipment] = useState({})
  const {companyInventory} = useCompanyInventory()
  const [isElementVisible,setElementVisible] = useState(true)
  

  
  const EQUIPMENT_URL = process.env.REACT_APP_API_BASE + "equipments/";
  const api_url = process.env.REACT_APP_API_BASE + url.POST_EQUIPMENT_DETAILS + '/project/' + props.projectId;
  
  const { equipments , deleteInvEquipment ,isdeleteLoading} = useEquipment(tog_equipmentModal,props.projectId)


  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
    }
  };
  

  function tog_equipmentModal() {
    setEquipmentModal(false);
    setshowDelete(false)

  }

  const fetchEquipment = () => {
    axios.get(api_url, config)
        .then(res => {
          setList(res.data.data);
        })
        .catch(error => {
          if (error.response) {
            if(error.response.status === 401 || error.response.status === 403) {
                props.history.push('/login');
            }
            else {
                setError(error.response.data.error[0]);
            }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
        });
  }

  useEffect(() => {
      
    fetchEquipment()
        
    }, []);

    function addEquipment(e) {
      e.preventDefault();
  
      var config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
      };
      
      var data = {
        projectId: props.projectId,
        equipmentName: equipmentName,
        rate: rate,
        ratePrice: ratePrice,
        capitalInvestment: capitalInvestment,
        description: description,
      };
      
      var api_url = process.env.REACT_APP_API_BASE + url.POST_EQUIPMENT_DETAILS;
      setSaving(true)
      axios.post(api_url, data, config)
      .then(res => {
        var newItemArr = [];
  
        newItemArr.push(res.data.data);
        var newListArr = newItemArr.concat(list);
        setList(newListArr);
        setEquipmentModal(false);
        setEquipmentName('');
        setRate('');
        setRatePrice('');
        setCapitalInvestment('');
        setDescription('');
        setSaving(false)

      })
      .catch(error => {
        setSaving(false)
        if (error.response) {
            if(error.response.status === 401 || error.response.status === 403) {
                props.history.push('/login');
            }
            else {
                setError(error.response.data.error[0]);
            }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
    }

    const handleDeleteEquipment = async () => {
      // setDeleting(true)
      try {
        await deleteInvEquipment(deleteItem)
      } catch (error) {
        console.log("error",error)
      }
    //  await axios.delete(EQUIPMENT_URL + deleteItem , config)
    //     .then((data) => {
    //       setDeleting(false)
    //       fetchEquipment()
    //       setshowDelete(false)
    //   })
     
      
    }
  
    const handleShowDelete = (id) => {
      setDeleteItem("")
      setshowDelete(true)
      setDeleteItem(id)
    }

    // Editing
  const handleEditing = (equipment) => {
    setEditing(true)
    setEquipment(equipment)
  }

  const handleEquipmentSubmit = async (e) => {
    e.preventDefault();
    setSaving(true)
    const data = new FormData(e.target)
    const response = {
      projectId: props.projectId,
      equipmentName: data.get("equipmentName"),
      rate: data.get("rate"),
      ratePrice: data.get("ratePrice"),
      capitalInvestment: data.get("capitalInvestment"),
      description: data.get("description"),
      equiUniqueIdentifier: equipment.equiUniqueIdentifier
    };

    await axios.patch(EQUIPMENT_URL + equipment._id ,response , config)
      .then((data) => {
        setSaving(false)
        fetchEquipment()
        setEditing(false)
    }).catch((error) => {
      setSaving(false)
    })

  }

  // console.log("capitalInvestment",capitalInvestment)
  const handleItemSource = (type) => {
    setCapitalInvestment(type)
    setElementVisible(prev => !prev)
  }

  // console.log("equipments",equipments)


  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader>
            <Row>
              <Col xl={6} md={6} xs={12}>
                <h4 className="card-title">List of Equipment</h4>
                <p className="card-title-desc mb-3">
                  Track all the equipment used and their rate
                </p>
              </Col>
              <Col xl={6} md={6} xs={12} style={{ textAlign: "right" }}>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => setEquipmentModal(true)}
                >
                  Add New
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Rate (hour, day, etc)</th>
                    <th>Hiring Price (per rate)</th>
                    <th>Owner</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {equipments.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No equipment added
                      </td>
                    </tr>
                  ) : (
                    <>
                      {map(equipments, (item, key) => (
                        <tr key={key}>
                          <th scope="row">{key + 1}</th>
                          <td>{item.equipmentName}</td>
                          <td>{item.rate}</td>
                          <td>{props.currency + " " + item.ratePrice}</td>
                          <td>{item.capitalInvestment ? "Self" : item?.owner}</td>
                          <td>
                            <div className="d-flex flex-row gap-3">
                              <a onClick={() => handleEditing(item)}>
                                <svg
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  height="1.5em"
                                  width="1.5em"
                                >
                                  <path d="M14.06 9l.94.94L5.92 19H5v-.92L14.06 9m3.6-6c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29m-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
                                </svg>
                              </a>
                              <a onClick={() => handleShowDelete(item._id)}>
                                <svg
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                  height="1em"
                                  width="1em"
                                >
                                  <path d="M6.5 1h3a.5.5 0 01.5.5v1H6v-1a.5.5 0 01.5-.5zM11 2.5v-1A1.5 1.5 0 009.5 0h-3A1.5 1.5 0 005 1.5v1H2.506a.58.58 0 00-.01 0H1.5a.5.5 0 000 1h.538l.853 10.66A2 2 0 004.885 16h6.23a2 2 0 001.994-1.84l.853-10.66h.538a.5.5 0 000-1h-.995a.59.59 0 00-.01 0H11zm1.958 1l-.846 10.58a1 1 0 01-.997.92h-6.23a1 1 0 01-.997-.92L3.042 3.5h9.916zm-7.487 1a.5.5 0 01.528.47l.5 8.5a.5.5 0 01-.998.06L5 5.03a.5.5 0 01.47-.53zm5.058 0a.5.5 0 01.47.53l-.5 8.5a.5.5 0 11-.998-.06l.5-8.5a.5.5 0 01.528-.47zM8 4.5a.5.5 0 01.5.5v8.5a.5.5 0 01-1 0V5a.5.5 0 01.5-.5z" />
                                </svg>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            {/* Add equipment */}
            <EquipmentModal isOpen={equipmentModal} onCloseClick={tog_equipmentModal} projectId={props.projectId} />
            <EditequipmentModal data={equipment} isOpen={isEditing} onCloseClick={() => setEditing(false)} projectId={props.projectId}/>
            {/* <Modal
              isOpen={equipmentModal}
              toggle={() => {
                tog_equipmentModal();
              }}
            >
              <Form onSubmit={(e) => addEquipment(e)}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Adding Equipment
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setEquipmentModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <Label htmlFor="capitalInvestment" className="form-Label">
                      Select Item Source
                    </Label>
                    <select
                      className="form-select"
                      onChange={(e) => handleItemSource(e.target.value)}
                      defaultValue={capitalInvestment}
                      required
                    >
                      <option value={true}>Self</option>
                      <option value={false}>Loaned</option>
                    </select>
                  </div>
                  {
                    isElementVisible  && (
                      <div className="mb-3">
                      <Label htmlFor="equipment-name" className="form-Label">
                        Select item
                      </Label>
                      <select
                        className="form-select"
                        onChange={(e) => setCapitalInvestment(e.target.value)}
                        defaultValue={capitalInvestment}
                        required
                      >
                        <option value="">
                          Select item from company inventory
                        </option>
                        {companyInventory.map((item) => {
                          return (
                            <>
                              <option key={item._id} value={item._id}>
                                {item.inventoryName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    )
                  }
                  
                  <div className="mb-3">
                    <Label htmlFor="equipment-name" className="form-Label">
                      Item name
                    </Label>
                    <Input
                      className={
                        "form-control"
                      }
                      type="text"
                      placeholder=""
                      defaultValue={equipmentName}
                      onChange={(e) => setEquipmentName(e.target.value)}
                      id="equipment-name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-Label">Rate</Label>
                    <select
                      className="form-select"
                      onChange={(e) => setRate(e.target.value)}
                      defaultValue={rate}
                      required
                    >
                      <option value="">Select</option>
                      <option value="hour">hour</option>
                      <option value="day">day</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="ratePrice" className="form-Label">
                      Hiring Price
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="5"
                      defaultValue={ratePrice}
                      onChange={(e) => setRatePrice(e.target.value)}
                      id="ratePrice"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <Label
                      htmlFor="basicpill-description-input"
                      className="form-Label"
                    >
                      Description (Optional)
                    </Label>

                    <Input
                      name="description"
                      className="form-control"
                      type="textarea"
                      id="basicpill-description-input"
                      placeholder="Needed for building foundation of building"
                      defaultValue={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    {error ? <Alert color="danger">{error}</Alert> : null}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      setEquipmentModal();
                    }}
                    className="btn btn-secondary "
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    disabled={isSaving}
                  >
                    {isSaving ? <LoadingIndicator /> : <span>Add</span>}
                  </button>
                </div>
              </Form>
            </Modal> */}
            <DeleteModal
              show={isShowDelete}
              onCloseClick={() => setshowDelete(false)}
              onDeleteClick={() => handleDeleteEquipment()}
              isLoading={isdeleteLoading}
            />
            {/* <Modal
              isOpen={isEditing}
              toggle={() => {
                setEditing(!isEditing);
              }}
            >
              <Form onSubmit={(e) => handleEquipmentSubmit(e)}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Edit Equipment
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setEditing(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <Label htmlFor="equipment-name" className="form-Label">
                      Item
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Excavator"
                      defaultValue={equipment?.equipmentName}
                      name="equipmentName"
                      // onChange={(e) => setEquipmentName(e.target.value)}
                      id="equipment-name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-Label">Rate</Label>
                    <select
                      className="form-select"
                      name="rate"
                      //onChange={(e) => setRate(e.target.value)}
                      defaultValue={equipment?.rate}
                      required
                    >
                      <option value="">Select</option>
                      <option value="hour">hour</option>
                      <option value="day">day</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="ratePrice" className="form-Label">
                      Hiring Price
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="5"
                      defaultValue={equipment?.ratePrice}
                      name="ratePrice"
                      // onChange={(e) => setRatePrice(e.target.value)}
                      id="ratePrice"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="capitalInvestment" className="form-Label">
                      Item source
                    </Label>
                    <select
                      className="form-select"
                      //onChange={(e) => setCapitalInvestment(e.target.value)}
                      defaultValue={equipment?.capitalInvestment}
                      name="capitalInvestment"
                      required
                    >
                      <option value="">Select</option>
                      <option value={true}>Self</option>
                      <option value={false}>Loaned</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label
                      htmlFor="basicpill-description-input"
                      className="form-Label"
                    >
                      Description (Optional)
                    </Label>

                    <Input
                      name="description"
                      className="form-control"
                      type="textarea"
                      id="basicpill-description-input"
                      placeholder="Needed for building foundation of building"
                      defaultValue={equipment?.description}
                      //onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    {error ? <Alert color="danger">{error}</Alert> : null}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      setEditing(false);
                    }}
                    className="btn btn-secondary "
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    disabled={isSaving}
                  >
                    {isSaving ? <LoadingIndicator /> : <span>Save</span>}
                  </button>
                </div>
              </Form>
            </Modal> */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default Equipment
