import React , {useState,useEffect} from "react"
import { useQuery , useQueryClient , useMutation  } from "@tanstack/react-query";
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from 'lodash';
import * as url from "../helpers/jengasmart_api_urls";

const MILESTONE_URL = process.env.REACT_APP_API_BASE + "milestones/project/";


export const useScheduleData = (projectId) => {
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("authUser")).token,
        },
      };

      const { data: projectScheduleData = [] , isLoading: isProjectScheduleLoading } = useQuery({
        queryKey: ['projectscheduleDetails'],
        initialData: [],
        queryFn: async () => {
            const {data} = await axios.get(MILESTONE_URL + projectId , config)
            const results = data;
       
            return results.data
        }
       
      }); 
      return { projectScheduleData }
}