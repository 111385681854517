import {
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
} from "./actionTypes"

export const userSetPassword = (user, history) => {
  return {
    type: SET_PASSWORD,
    payload: { user, history },
  }
}

export const userSetPasswordSuccess = message => {
  return {
    type: SET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userSetPasswordError = message => {
  return {
    type: SET_PASSWORD_ERROR,
    payload: message,
  }
}
