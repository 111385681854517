import React, { useEffect, useState } from 'react';
import {useParams , useHistory , Link  } from 'react-router-dom';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as url from "../../helpers/jengasmart_api_urls";

import moment from 'moment';

import axios from 'axios';
import queryString from 'query-string';
import { useQuery } from "@tanstack/react-query";
import { LoadingIndicator } from "../../components/Common";


export function ProjectCard(props){
    // console.log("props",props)
    return (
        <div className="d-flex flex-row justify-content-between gap-3">
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h4>{props.title}</h4>
              <h6>
                {props.title === "Milestone"
                  ? props.data?.taskName
                  : props.title === "Task"
                  ? props.data?.tasks[0].taskName
                  : ""}
              </h6>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h4>Description</h4>
              <h6>
              {props.title === "Milestone"
                  ? props.data?.description
                  : props.title === "Task"
                  ? props.data?.tasks[0].description
                  : ""}
              </h6>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h4>Assignee</h4>
              <h6>
                {JSON.parse(localStorage.getItem("authUser")).user.email}
              </h6>
            </div>
          </div>
        </div>
      </div>
    );
}

const ViewSchedule = (props) => {

    const {milestoneId} = useParams();

    const history = useHistory();

    
    const searchQuery = new URLSearchParams(document.location.search)
    
   const projectName= searchQuery.get('project') ? searchQuery.get('project') : 'Project Schedule'

    const MILESTONE_URL = process.env.REACT_APP_API_BASE + "milestones/" + milestoneId;
    
    let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("authUser")).token,
        },
      };

    async function fetchProjectMilestones(){
        const {data} = await axios.get(MILESTONE_URL,config)

        return data;
    }

    const { data: milestones = [] } = useQuery({
      queryKey: ["milestone"],
      queryFn: fetchProjectMilestones,
      initialData: [],
    });

     
    

     return (
        <div className="page-content" >
            <div className='container-fluid'>   
            <i
            className="fas fa-arrow-circle-left"
            style={{
              fontSize: 20,
              color: "blue",
              cursor: "pointer",
              marginBottom: 10,
            }}
            onClick={() => props.history.goBack()}
          ></i>{" "}
          <div className="d-flex justify-between">
            <Breadcrumbs title="Dashboard" breadcrumbItem={"View Project Schedule"} />
            {/* <Link className="btn btn-primary" to={`/projects/view-task/${milestoneId}?project=${props.projectName}`}> Edit </Link> */}
          </div>
          
            <div className="row">
                <div className='col-lg-12'>
                    <div className="card">
                       <div className='card-body'>
                            <div className="d-flex flex-column mb-2">
                                <h4>Project Milestone</h4>
                            </div>
                            {
                                milestones.length !== 0 ? (
                                    <ProjectCard data={milestones?.data} title={"Milestone"} />
                                ) : <LoadingIndicator />
                            }
                             
                            <div className="d-flex flex-column mb-2">
                                <h4>Project Tasks</h4>
                            </div>
                            {
                                milestones.length !== 0 ? (
                                    <ProjectCard data={milestones?.data} title={"Task"} />
                                ) : <LoadingIndicator />
                            }
                            
                       </div>
                    </div>

                </div>
            </div>
            </div>
        </div>
     ) 
}

export default ViewSchedule;