import React from 'react'
import {useField} from '@formiz/core'
import 'react-calendar/dist/Calendar.css';
import Calendar from "react-calendar";

export function CalendarField(props){
    const {setValue,value , isValid , errorMessage  , isSubmitted , id } = useField(props)
    const { handleData , minDate= null , required } = props
    const [isTouched, setIsTouched] = React.useState(false)
   const showError = !isValid && (isTouched || isSubmitted)

    const handleDateChange = (nextValue) => {
        setValue(nextValue)
        handleData(nextValue)
    }

    return (
      <div>
            <input 
            //    name="projectName" 
            //    placeholder={placeholder || ''} 
               id={id} 
               type={'text' }  
               className={`form-control ${(showError) ? 'is-invalid' : ''}`} 
               value={value ?? ''}
               onChange={e => handleDateChange}
               onBlur={() => setIsTouched(true)}
               aria-invalid={showError}
               aria-required={!!required}
               aria-describedby={showError ? `${id}-error` : null}
               style={{
                  background: "#00000008",
                  display: "none"
                }}
              />
           <Calendar 
              onChange={handleDateChange}
              value={value}
              minDate={minDate}
              //onBlur={() => setIsTouched(true)}
            />
          { showError && (
             <div className='invalid-feedback d-block mt-2'>
             { errorMessage }
             </div>
           )}
      </div>  
       
    )
}