import PropTypes from 'prop-types'
import React , {useState,useEffect} from "react"
import { 
    Modal, 
    ModalBody ,  
    Alert
} from "reactstrap"
import { Formiz, useForm } from '@formiz/core'
import { InputField } from "../../components/forms/InputField.js";
import { SelectField } from '../forms/SelectField.js';
import { TextAreaField } from '../forms/TextAreaField.js';
import { CalendarField } from '../forms/CalendarField.js';
import { LoadingIndicator } from "../../components/Common";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useCompanyInventory } from '../../hooks/inventoryHook.js';
import { isEmpty } from 'lodash';

export function MoveItemModal({isopenMoveItem,onCloseClick,assignItem ,  projectSites , uniqueCollaborators}){
    const assignItemForm = useForm()
    const {isLoading,assignInventory,isAssignLoading} = useCompanyInventory(onCloseClick)

    const handleSubmit = async (values) => { 
        const result = {
            ...values,
            serialNumber: assignItem.serialNumber,
            modelNumber: assignItem.modelNumber,
            manufacturer: assignItem.manufacturer,
            description: assignItem.description,
            projectId: values.projectSite,
            _id: assignItem._id,
        }
      //  handleItem(result)
       try {
        await assignInventory(result)
      } catch (error){
        console.log("error" , error)
      }
    }

    // console.log("moveItem",assignItem)

   
    return (
      <Modal
        isOpen={isopenMoveItem}
        toggle={onCloseClick}
        centered={true}
        size={"lg"}
      >
        <Formiz 
           connect={assignItemForm}
           onValidSubmit={handleSubmit}
           initialValues={{
            ...assignItem,
            borrowDate: assignItem?.borrowDate?.split("T")[0],
            returnDate: assignItem?.returnDate?.split("T")[0]

           }}
           >
          

          <form
            className="w-100 needs-validation"
            noValidate
            onSubmit={assignItemForm.submit}
          >
            {/* <h3>Add Inventory</h3> */}
            <div className="d-flex flex-column w-100 p-4">
              <h3 className="mb-1" style={{color: "#194973"}}>Move Item</h3>
              <div className="d-flex flex-row w-100 my-3">
                <div className="col-6">
                  <h4 className="mb-2 pb-2" style={{ color: "#194973" }}>
                    {assignItem?.inventoryName}
                  </h4>
                  <h6 className="mb-1 pb-2">
                    Manufacturer: {assignItem?.manufacturer}
                  </h6>
                  <h6 className="mb-1 pb-2">
                    Serial Number: {assignItem?.serialNumber}
                  </h6>
                  <h6 className="mb-1 pb-2">
                    Model Number: {assignItem?.modelNumber}
                  </h6>
                </div>
                <div className="col-6">
                  <h4 className="mb-2" style={{ color: "#194973" }}>
                    Description
                  </h4>
                  <h6>{assignItem?.description}</h6>
                </div>
              </div>
              <div className="d-flex flex-row w-100">
                <div className="col-6 p-2">
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Borrow date</h6>
                    <InputField
                      name={"borrowDate"}
                      required={"Please select a date"}
                      type="date"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Return date</h6>
                    <InputField
                      name={"returnDate"}
                      required={"Please select a date"}
                      type="date"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  {/* <div className="w-100  mb-3">
                    <h6 className="mb-1">Quantity</h6>
                    <InputField
                      name={"assignedQuantity"}
                      required={"Please enter a quantity"}
                      type="number"
                      placeholder=""
                      isDefault={false}
                    />
                  </div> */}
                </div>
                <div className="col-6 p-2">
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Current project site</h6>
                    <SelectField
                      name={"projectId"}
                      required={"please select a project site"}
                      data={projectSites}
                      defaultOption="Select a project site"
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">New project site</h6>
                    <SelectField
                      name={"projectSite"}
                      required={"please select a new project site"}
                      data={projectSites}
                      defaultOption="Select a project site"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-end gap-3">
                <button className="btn btn-primary mb-3" type="submit">
                  {isLoading ? <LoadingIndicator /> : "Save"}
                </button>
                <a
                  className="align-self-center"
                  onClick={onCloseClick}
                  style={{
                    textDecoration: "underline !important",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </a>
              </div>
            </div>
          </form>
        </Formiz>
        <ToastContainer />
      </Modal>
    );
}

MoveItemModal.propTypes = {
    onCloseClick: PropTypes.func,
    isopenMoveItem: PropTypes.bool,
    assignItem: PropTypes.object,
    projectSites: PropTypes.array,
    uniqueCollaborators: PropTypes.array 
    // projectId: PropTypes.string,
    // taskType: PropTypes.number,
    // data: PropTypes.object

}