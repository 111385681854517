import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {
    Alert,
    Container,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    FormGroup,
    Label,
    Input,
    Button,
    FormFeedback,
    Form,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap';
// import Select from "react-select"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as url from "../../helpers/jengasmart_api_urls";

import axios from 'axios';
import { map } from "lodash";
import queryString from 'query-string';

const AddSubTask = (props) => {
  const params = useParams();
  const parsed = queryString.parse(location.search);

  const [projectError, setProjectError] = useState('');
  const [collaboratorsList, setCollaboratorsList] = useState([]);
  const [subTaskList, setSubTaskList] = useState([]);

  const [projectId, setProjectId] = useState(params.id);
  const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('authUser')).user.id);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [taskId, setTaskId] = useState(parsed.taskId);
  const [subtaskName, setSubTaskName] = useState('');
  const [assignee, setAssignee] = useState('');
  const [description, setDescription] = useState('');

    //meta title
    document.title = "Add Sub Task | Jenga App";
    
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            projectId: params.id,
            userId: JSON.parse(localStorage.getItem('authUser')).user.id,
            startDate: '',
            endDate: '',
            taskId: parsed.taskId,
            subtaskName: '',
            assignee: '',
            description: ''
        },
        validationSchema: Yup.object({
          startDate: Yup.date().required("Please Enter a start date"),
          endDate: Yup.date().required("Please Enter an end date"),
          subtaskName: Yup.string().required("Please Enter sub task title"),
          description: Yup.string().required("Please Enter Your Description"),
        }),
        onSubmit: (values) => {
            var config = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
              }
            };
            
            var api_url = process.env.REACT_APP_API_BASE + url.POST_TASKS_DETAILS;
        
            axios.post(api_url, values, config)
            .then(res => {
              props.history.push(`/projects/${params.id}/?selectedtab=2`);
            })
            .catch(error => {
              if (error.response) {
                setProjectError(error.response.data.error[0]);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
            });
        }
    });

    useEffect(() => {
      var config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
          }
        };
        
        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/collaborators/' + params.id;
    
        axios.get(api_url, config)
        .then(res => {
          setCollaboratorsList(res.data.data);
        })
        .catch(error => {
          if (error.response) {
            if(error.response.status === 401 || error.response.status === 403) {
                props.history.push('/login');
            }
            else {
                setProjectError(error.response.data.error[0]);
            }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
        });
    }, []);

    function addSubtaskToList() {
        var newSubtaskObj = {};
        var newSubtaskArr = [];
        
        newSubtaskObj['projectId'] = projectId;
        newSubtaskObj['userId'] = userId;
        newSubtaskObj['startDate'] = startDate;
        newSubtaskObj['endDate'] = endDate;
        newSubtaskObj['taskId'] = taskId;
        newSubtaskObj['subtaskName'] = subtaskName;
        newSubtaskObj['assignee'] = assignee;
        newSubtaskObj['description'] = description;

        newSubtaskArr.push(newSubtaskObj);

        var updatedList = subTaskList.concat(newSubtaskArr);

        setSubTaskList(updatedList);
        setStartDate('');
        setEndDate('');
        setSubTaskName('');
        setAssignee('');
        setDescription('');
    }


    function removeSubtask(id) {
        var newSubtaskArr = [];
  
        newSubtaskArr = subTaskList.splice(id, 1);
  
        setSubTaskList(newSubtaskArr);
    }

    function onSubmitSubTasks() {

        if(subTaskList.length > 0) {
            var config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
                }
              };

              var data = {
                taskId: parsed.taskId,
                subtasks: subTaskList
              };
              
              var api_url = process.env.REACT_APP_API_BASE + url.POST_SUBTASKS_DETAILS;
          
              axios.post(api_url, data, config)
              .then(res => {
                props.history.push(`/projects/${params.id}/?selectedtab=2`);
              })
              .catch(error => {
                if (error.response) {
                  setProjectError(error.response.data.error[0]);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('Error', error.message);
                }
              });
        }
    }


    /* function submitImages() {
      var config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
      };

      var bodyFormData = new FormData();
      if(selectedFiles !== null) {
        selectedFiles.forEach(file => {
          bodyFormData.append("image", file);
        });
      }
      
      var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/image/' + projectId;
  
      axios.put(api_url, bodyFormData, config)
      .then(res => {
        setactiveTab(3);
        setProjectId(res.data.data._id);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
    }

    function handleAcceptedFiles(files) {
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      setselectedFiles(files);
    } */
  
    /**
     * Formats the size
     */
    /* function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    } */

    /* function getItems(projectId) {
      setProjectId(projectId);

      var config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
      };

      var api_url = process.env.REACT_APP_API_BASE + url.POST_MATERIAL_DETAILS + '/project/' + projectId;

      var api_url1 = process.env.REACT_APP_API_BASE + url.POST_EQUIPMENT_DETAILS + '/project/' + projectId;

      var api_url2 = process.env.REACT_APP_API_BASE + url.POST_LABOUR_DETAILS + '/project/' + projectId;
  
      axios.get(api_url, config)
      .then(res => {
        setMaterialsList(res.data.data);
        setMaterialButtonDisabled(false);
        setAdditionalCostButtonDisabled(false);
      })
      .catch(error => {
        setMaterialButtonDisabled(true);
        if (error.response) {
          if(error.response.status === 401 || error.response.status === 403) {
              props.history.push('/login');
          }
          else {
              setError(error.response.data.error[0]);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
  
      axios.get(api_url1, config)
      .then(res => {
        setEquipmentList(res.data.data);
        setEquipmentButtonDisabled(false);
      })
      .catch(error => {
        setEquipmentButtonDisabled(true);
        if (error.response) {
          if(error.response.status === 401 || error.response.status === 403) {
              props.history.push('/login');
          }
          else {
              setError(error.response.data.error[0]);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
  
      axios.get(api_url2, config)
      .then(res => {
        setLabourList(res.data.data);
        setLabourButtonDisabled(false);
      })
      .catch(error => {
        setLabourButtonDisabled(true);
        if (error.response) {
          if(error.response.status === 401 || error.response.status === 403) {
              props.history.push('/login');
          }
          else {
              setError(error.response.data.error[0]);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
    } */
  

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid id="basic-pills-wizard" className="twitter-bs-wizard">
                    {/* Render Breadcrumbs */}
                    <i className="fas fa-arrow-circle-left" style={{ fontSize: 20, color: 'blue', cursor: 'pointer', marginBottom: 10 }} onClick={() => props.history.goBack()}></i> <Breadcrumbs title="Dashboard" breadcrumbItem="Add Subtask" />

                    <Row>
                        <Col xl={6}>
                          <Form 
                            className="needs-validation"
                            onSubmit={(e) => {
                                e.preventDefault();
                                onSubmitSubTasks();
                                return false;
                            }}>
                            <Card className="mb-5">
                                <CardHeader>
                                    <h4 className="card-title">You can add subtasks for the task you created</h4>
                                    <p className="card-title-desc mb-3">Fill in the details for the subtask and add it to the list. You can add as many subtasks as possible and submit all at once.</p>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                      <Col md="12">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="example-datetime-local-input" className="form-Label">Start Date</Label>
                                          <Input 
                                            name="startDate"
                                            className="form-control"
                                            type="date"
                                            id="example-datetime-local-input"
                                            onChange={e => setStartDate(e.target.value)}
                                            value={startDate} />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="12">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="example-datetime-local-input" className="form-Label">End Date</Label>
                                          <Input 
                                            name="endDate"
                                            className="form-control"
                                            type="date"
                                            id="example-datetime-local-input"
                                            onChange={e => setEndDate(e.target.value)}
                                            value={endDate} />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="12">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="example-datetime-local-input" className="form-Label">Title</Label>
                                          <Input 
                                            name="subtaskName"
                                            className="form-control"
                                            type="text"
                                            id="example-datetime-local-input"
                                            placeholder="Project Initialization Sequence"
                                            onChange={e => setSubTaskName(e.target.value)}
                                            value={subtaskName} />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="mb-3">
                                            <Label className="form-label">Assignee</Label>
                                            <select 
                                              className="form-select"
                                              name="assignee"
                                              onChange={e => setAssignee(e.target.value)}
                                              value={assignee}>
                                                <option value="">Select</option>
                                                {collaboratorsList.map((item, key) => (
                                                    <option value={item._id} key={key}>{item.memberEmail}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="12">
                                            <FormGroup className="mb-3">
                                            <Label htmlFor="basicpill-address-input" className="form-Label">Description</Label>

                                            <Input 
                                                name="description"
                                                className="form-control"
                                                type="textarea" 
                                                id="basicpill-address-input"
                                                onChange={e => setDescription(e.target.value)}
                                                value={description} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <button
                                        type="button"
                                        className="btn btn-link btn-rounded waves-effect"
                                        onClick={() => props.history.push(`/projects/${params.id}/?selectedtab=2`)}
                                    >
                                        Skip
                                    </button>
                                    <Button color="primary" onClick={() => addSubtaskToList()}>
                                        Add To List
                                    </Button>
                                    
                                </CardBody>
                            </Card>

                            <h4 className="card-title mb-3">Click here to submit list of subtasks</h4>

                            <Button color="success" type="submit">
                              Submit List
                            </Button>
                          </Form>
                        </Col>
                        <Col xl={6}>
                          <Row>
                          {subTaskList.map((item, key) => (
                            <Col xl="12" sm="12" key={key}>
                              <Card className="text-center">
                                <CardBody>
                                  <UncontrolledDropdown className="dropdown text-end">
                                    <DropdownToggle className="text-muted font-size-16" tag="a">
                                      <i className="bx bx-dots-horizontal-rounded"></i>
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-menu-end">
                                      <DropdownItem to="#" onClick={() => removeSubtask(key)}>Remove</DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>

                                  <h1 className="font-size-20 mb-3" style={{ textAlign: 'left' }}>
                                    Subtask {Number(key) + 1}
                                  </h1>

                                  <h5 className="font-size-15 mb-1" style={{ textAlign: 'left' }}>
                                    {item.subtaskName}
                                  </h5>
                                  <p className="text-muted mb-2" style={{ textAlign: 'left' }}>{item.description}</p>
                                </CardBody>
                              </Card>
                            </Col>
                           ))}
                          </Row>
                        </Col>
                      </Row>
              </Container>
            </div>
        </React.Fragment>
    );
}

export default AddSubTask;