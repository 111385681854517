import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import avtar4 from "../../assets/images/users/avatar-4.jpg";
import avtar3 from "../../assets/images/users/avatar-3.jpg";
import avtar5 from "../../assets/images/users/avatar-5.jpg";
import avtar1 from "../../assets/images/users/avatar-1.jpg";

// import error from "../../assets/images/error-img.png";

const OnboardingNewTeam = () => {
    return (
        <React.Fragment>
            <div className="my-5 pt-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mb-5">
                                {/* <h1 className="display-1 fw-semibold">Welcome, <span className="text-primary mx-2">Kwame</span></h1> */}
                                <h4 className="mb-5">Add Team Members</h4>

                                <div className="mt-4 mt-md-0">
                                    <img className="img-thumbnail rounded-circle avatar-md" alt="200x200" src={avtar4} data-holder-rendered="true" />

                                    <img className="img-thumbnail rounded-circle avatar-md" alt="200x200" src={avtar3} data-holder-rendered="true" />

                                    <img className="img-thumbnail rounded-circle avatar-md" alt="200x200" src={avtar5} data-holder-rendered="true" />

                                    <img className="img-thumbnail rounded-circle avatar-md" alt="200x200" src={avtar1} data-holder-rendered="true" />
                                </div>

                                <div className="form-floating mt-5 mb-3 mb-lg-0">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                  />
                                  <label htmlFor="floatingInput">Team Member Email</label>

                                  <div className="mt-5 text-center">
                                    <Link className="btn btn-primary" to="/projects">Next</Link>
                                  </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <div className="row justify-content-center">
                        <div className="col-md-10 col-xl-8">
                            <div>
                                <img src={error} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div> */}
                </Container>
            </div>
        </React.Fragment>
    );
}

export default OnboardingNewTeam;