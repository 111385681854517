import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import SetPassword from "../pages/Authentication/SetPassword";

//AuthenticationInner related pages
import TwoStepVerfication from "../pages/AuthenticationInner/TwoStepVerfication";
import CodeConfirmationSuccess from "../pages/AuthenticationInner/CodeConfirmationSuccess";
import PasswordChangeSuccess from "../pages/AuthenticationInner/PasswordChangeSuccess";
import userProfile from "../pages/Authentication/user-profile";

//JengaSmart
import OnboardingNewProject from "../pages/OnboardingNewProject/index";
import OnboardingNewTeam from "../pages/OnboardingNewTeam/index";
import TrackLogs from "../pages/TrackLogs/index";
import ViewLog from "../pages/ViewLog/index";
import AddTrackLog from "../pages/AddTrackLog/index";
import AddTask from "../pages/AddTask/index";
import AddSubTask from "../pages/AddSubTask/index";
import Projects from "../pages/Projects/index";
import ViewProject from "../pages/ViewProject/index";
import NewProject from "../pages/NewProject/index";
import ViewSchedule from "../pages/ViewProject/ViewSchedule";
import EditSchedule from "../pages/ViewProject/EditSchedule";
import {CompanyInventory} from "../pages/Inventory/"
import { CrossSiteInventory } from "../pages/Inventory/cross-site";
import Reports from "../pages/Reports";
import ReportDetails from "../pages/Reports/ReportDetails";
import EditLog from "../pages/EditLog";
import Schedule from "../pages/ViewProject/Schedule";
import DailyLogs from "../pages/ViewProject/DailyLogs";
import Dashboard from "../pages/ViewProject/newStructure/Dashboard";
import DailyLogsList from "../pages/ViewProject/newStructure/DailyLogsList";
import NewSchedule from "../pages/ViewProject/newStructure/NewSchedule";
import Materials from "../pages/ViewProject/newStructure/Materials";
import Equipment from "../pages/ViewProject/newStructure/Equipment";
import Labour from "../pages/ViewProject/newStructure/Labour";
import Collaborators from "../pages/ViewProject/newStructure/Collaborators";

const userRoutes = [
  //profile
  { path: "/profile", component: userProfile },

  // Jengasmart
  { path: "/track-progress", component: TrackLogs },
  { path: "/view-progress/:id", component: ViewLog },
  { path: "/add-progress/:id", component: AddTrackLog },
  { path: "/add-task/:id", component: AddTask },
  { path: "/add-subtask/:id", component: AddSubTask },
  { path: "/projects", component: Projects },
  { path: "/projects/:id", component: ViewProject },
  { path: "/new-project", component: NewProject },
  { path: "/projects/view-task/:milestoneId", component: ViewSchedule },
  { path: "/projects/view-task/:milestoneId/edit", component: EditSchedule },
  { path: "/inventory", component: CompanyInventory },
  { path: "/inventory/cross-site", component: CrossSiteInventory },
  { path: "/reports", component: Reports },
  { path: "/reports/:id", component: ReportDetails },
  {path: "/edit-log/:id", component:EditLog},
  {path: "/schedule/:id", component:NewSchedule},
  {path: "/daily-logs/:id", component:DailyLogsList},
  {path: "/dashboard/:id", component:Dashboard},
  {path: "/materials/:id", component:Materials},
  {path: "/equipment/:id", component:Equipment},
  {path: "/labour/:id", component:Labour},
  {path: "/collaborators/:id", component:Collaborators},
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/projects" /> },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/confirm-email", component: TwoStepVerfication },
  { path: "/confirmation-success", component: CodeConfirmationSuccess },
  { path: "/password-change-success", component: PasswordChangeSuccess },
  { path: "/set-password/:email/:code", component: SetPassword },

  // Jengasmart
  { path: "/onboarding-new-project", component: OnboardingNewProject, isAuthProtected: true },
  { path: "/onboarding-new-member", component: OnboardingNewTeam, isAuthProtected: true },
  
];

export { userRoutes, authRoutes };
