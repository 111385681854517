import React, { useEffect, useState } from "react"
import {Link, useLocation, useParams} from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader, Button
} from "reactstrap"
import { isEmpty, map } from "lodash"
import 'react-image-lightbox/style.css';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames";

import * as url from "../../helpers/jengasmart_api_urls";

//Import Image
import logo from "../../assets/images/logo-sm.svg"
import { getInvoiceDetail as onGetInvoiceDetail } from "../../store/invoices/actions"
//redux
import { useSelector, useDispatch } from "react-redux"

import axios from 'axios';
import moment from 'moment';
import Lightbox from "react-image-lightbox";

const ViewLog = props => {

  //meta title
  document.title = "Log Detail | Jenga App";

  const dispatch = useDispatch()
  const params = useParams()

  const [activeTab, setactiveTab] = useState("1");
  const [logDetail, setLogDetail] = useState({});

  const { invoiceDetail } = useSelector(state => ({
    invoiceDetail: state.invoices.invoiceDetail,
  }))
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openLightbox = (index) => {
    setSelectedImageIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };
  // const {
  //   match: { params },
  // } = props

  useEffect(() => {
    var config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
      };
      
      var api_url = process.env.REACT_APP_API_BASE + url.POST_LOG_DETAILS + '/' + params.id;

      axios.get(api_url, config)
      .then(res => {
        setLogDetail(res.data.data);
      })
      .catch(error => {
        if (error.response) {
          if(error.response.status === 401 || error.response.status === 403) {
              props.history.push('/login');
          }
          else {

          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  //Print the log
  const printInvoice = () => {
    window.print()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <i className="fas fa-arrow-circle-left" style={{ fontSize: 20, color: 'blue', cursor: 'pointer', marginBottom: 10 }} onClick={() => props.history.goBack()}></i> <Breadcrumbs title="Log Details" breadcrumbItem="Log Detail" />
          {!isEmpty(logDetail) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xl={6} md={6} xs={12}>
                        <h4 className="card-title">Log Details</h4>
                      </Col>
                      <Col xl={6} md={6} xs={12} style={{ textAlign: 'right' }}>
                        <Button
                            type="button"
                            color="primary"
                            onClick={() => {
                              props.history.push(`/edit-log/${logDetail._id}?project=${logDetail.projectId._id}`);
                            }}>
                          Edit Log
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="invoice-title">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <div className="mb-4">
                            <img src={logo} alt="" height="24" /><span className="logo-txt"></span>
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <div className="mb-4">
                            <h4 className="float-end font-size-16">{/* Log #  {logDetail._id} */}</h4>
                          </div>
                        </div>
                      </div>
                      <address>
                        <span><b>Project Name</b></span>
                        <br />
                        <span>{logDetail?.projectId?.projectName}</span>
                        <br />
                      </address>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col sm="6">
                        <div>
                          <h5 className="font-size-15">User:</h5>
                          <span>{logDetail?.userId?.firstName + " " + logDetail?.userId?.lastName}</span>
                          <br />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div>
                          <div>
                            <h5 className="font-size-15">Log Date:</h5>
                            <p>{moment(logDetail.logDate).format("MMMM Do YYYY")}</p>
                          </div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mt-4">
                          <h5 className="font-size-15">Weather Condition:</h5>
                          <p className="mb-1">{logDetail.weatherCondition}</p>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mt-4">
                          <h5 className="font-size-15">Task Name:</h5>
                          <p className="mb-1">{logDetail.taskTagged?.taskName}</p>
                        </div>
                      </Col>
                      <Col sm="12">
                        <div className="mt-4">
                          <h5 className="font-size-15">Extra Notes:</h5>
                          <span>{logDetail?.description}</span>
                          <br />
                        </div>
                      </Col>

                    </Row>

                    {
                        logDetail.image.length > 0 && (
                           <>
                             <div className="py-2 mt-3">
                               <h3 className="font-size-15 fw-bold">Photos</h3>
                             </div>
                             <Row>
                               {map(
                                   logDetail.image,
                                   (item, key) => (
                                       <Col sm={3} md={2} lg={2} key={key}>
                                         <img src={item} className={"w-100 log-image"} onClick={() => openLightbox(key)}
                                              style={{ cursor: 'pointer' }}/>
                                       </Col>
                                   )
                               )}
                             </Row>
                             {lightboxIsOpen && (
                                 <Lightbox
                                     mainSrc={logDetail.image[selectedImageIndex]}
                                     nextSrc={logDetail.image[(selectedImageIndex + 1) % logDetail.image.length]}
                                     prevSrc={logDetail.image[(selectedImageIndex + logDetail.image.length - 1) % logDetail.image.length]}
                                     onCloseRequest={closeLightbox}
                                     onMovePrevRequest={() =>
                                         setSelectedImageIndex((selectedImageIndex + logDetail.image.length - 1) % logDetail.image.length)
                                     }
                                     onMoveNextRequest={() =>
                                         setSelectedImageIndex((selectedImageIndex + 1) % logDetail.image.length)
                                     }
                                 />
                             )}
                           </>
                        )
                    }
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 fw-bold">Cost Summary</h3>
                    </div>
                    <Row>
                        <Col sm={12}>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === "1",
                                })}
                                onClick={() => {
                                  toggle("1");
                                }}
                              >
                                Materials
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === "2",
                                })}
                                onClick={() => {
                                  toggle("2");
                                }}
                              >
                                Equipment
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === "3",
                                })}
                                onClick={() => {
                                  toggle("3");
                                }}
                              >
                                Labour
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === "4",
                                })}
                                onClick={() => {
                                  toggle("4");
                                }}
                              >
                                Additional Costs
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab === "5",
                                  })}
                                  onClick={() => {
                                    toggle("5");
                                  }}
                              >
                                SubContractor Costs
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab === "6",
                                  })}
                                  onClick={() => {
                                    toggle("6");
                                  }}
                              >
                                Quantities Cost
                              </NavLink>
                            </NavItem>
                          </Nav>

                          <TabContent activeTab={activeTab} className="pt-3 text-muted">
                            <TabPane tabId="1">
                              <div className="p-4 border rounded">
                                <div className="table-responsive">
                                  <Table className="table-nowrap align-middle mb-0">
                                    <thead>
                                      <tr>
                                        <th style={{ width: "70px" }}>No.</th>
                                        <th>Item</th>
                                        <th className="text-end">Price</th>
                                        <th className="text-end">Quantity</th>
                                        <th className="text-end">Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {map(
                                        logDetail.materialsUsed,
                                        (item, key) => (
                                          <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>
                                              <h5 className="font-size-15 mb-1">{item.materialName}</h5>
                                              <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p>
                                            </td>
                                            <td className="text-end">{item.materialPrice}</td>
                                            <td className="text-end">{item.materialQuantity}</td>
                                            <td className="text-end">{Number(item.materialQuantity) * Number(item.materialPrice)}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="2">
                              <div className="p-4 border rounded">
                                <div className="table-responsive">
                                  <Table className="table-nowrap align-middle mb-0">
                                    <thead>
                                      <tr>
                                        <th style={{ width: "70px" }}>No.</th>
                                        <th>Item</th>
                                        <th className="text-end">Rate</th>
                                        <th className="text-end">Quantity</th>
                                        <th className="text-end">Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {map(
                                        logDetail.equipmentUsed,
                                        (item, key) => (
                                          <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>
                                              <h5 className="font-size-15 mb-1">{item.equipmentName}</h5>
                                              {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                            </td>
                                            <td className="text-end">{item.equipmentRatePrice}</td>
                                            <td className="text-end">{item.equipmentNumber}</td>
                                            <td className="text-end">{Number(item.equipmentRatePrice) * Number(item.equipmentNumber)}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="3">
                              <div className="p-4 border rounded">
                                <div className="table-responsive">
                                  <Table className="table-nowrap align-middle mb-0">
                                    <thead>
                                      <tr>
                                        <th style={{ width: "70px" }}>No.</th>
                                        <th>Item</th>
                                        <th className="text-end">Rate</th>
                                        <th className="text-end">Quantity</th>
                                        <th className="text-end">Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {map(
                                        logDetail.labourUsed,
                                        (item, key) => (
                                          <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>
                                              <h5 className="font-size-15 mb-1">{item.labourName}</h5>
                                              {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                            </td>
                                            <td className="text-end">{item.labourRatePrice}</td>
                                            <td className="text-end">{item.labourNumber}</td>
                                            <td className="text-end">{Number(item.labourRatePrice) * Number(item.labourNumber)}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="4">
                              <div className="p-4 border rounded">
                                <div className="table-responsive">
                                  <Table className="table-nowrap align-middle mb-0">
                                    <thead>
                                      <tr>
                                        <th style={{ width: "70px" }}>No.</th>
                                        <th>Item</th>
                                        <th className="text-end">Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {map(
                                        logDetail.additionalCostsUsed,
                                        (item, key) => (
                                          <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>
                                              <h5 className="font-size-15 mb-1">{item.additionalCostDescription}</h5>
                                              {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                            </td>
                                            <td className="text-end">{item.additionalCostAmount}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="5">
                              <div className="p-4 border rounded">
                                <div className="table-responsive">
                                  <Table className="table-nowrap align-middle mb-0">
                                    <thead>
                                    <tr>
                                      <th style={{ width: "70px" }}>No.</th>
                                      <th>Subcontractor Name</th>
                                      <th className="text-end">Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {map(
                                        logDetail.subContractorCostsUsed,
                                        (item, key) => (
                                            <tr key={key}>
                                              <td>{key + 1}</td>
                                              <td>
                                                <h5 className="font-size-15 mb-1">{item.subContractorName}</h5>
                                                {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                              </td>
                                              <td className="text-end">{item.subContractorCostAmount}</td>
                                            </tr>
                                        )
                                    )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="6">
                              <div className="p-4 border rounded">
                                <div className="table-responsive">
                                  <Table className="table-nowrap align-middle mb-0">
                                    <thead>
                                    <tr>
                                      <th style={{ width: "70px" }}>No.</th>
                                      <th>Quantity Name</th>
                                      <th>Unit</th>
                                      <th className="text-center">Qty</th>
                                      <th className="text-end">Rate</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {map(
                                        logDetail.quantitiesCostsUsed,
                                        (item, key) => (
                                            <tr key={key}>
                                              <td>{key + 1}</td>
                                              <td>
                                                <h5 className="font-size-15 mb-1">{item.quantitiesName}</h5>
                                                {/* <p className="font-size-13 text-muted mb-0">{item.materialUniqueId} </p> */}
                                              </td>
                                              <td>{item.quantitiesUnit}</td>
                                              <td className="text-center">{item.quantitiesQty}</td>
                                              <td className="text-end">{item.quantitiesRate}</td>
                                            </tr>
                                        )
                                    )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </TabPane>
                          </TabContent>
                        </Col>
                    </Row>

                    <div className="d-print-none text-end mt-3">
                      <strong>Total Cost</strong> <h4 className="m-0">{logDetail.projectId?.currency + " " + logDetail.totalLogAmount}</h4>
                    </div>
                    <div className="d-print-none mt-3">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        {/* <Link to="#" className="btn btn-primary me-2">
                          <i className="fa fa-share" />
                        </Link> */}
                      </div>
                    </div>


                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewLog
