import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./jengasmart_api_urls"

const options = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
}

const baseUrl = process.env.REACT_APP_API_BASE;

// Login Method
const postLoginDetails = data => {
  return axios
    .post(baseUrl + url.POST_LOGIN_DETAILS, data, options)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            message = err.response.data.msg
            break
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Register Method
const postRegisterDetails = data => {
  return axios
    .post(baseUrl + url.POST_REGISTER_DETAILS, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            message = err.response.data.msg
            break
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Reset Password Method
const postResetPasswordEmail = data => {
  return axios
    .post(baseUrl + url.POST_LOGIN_DETAILS + '/reset-password', data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            message = err.response.data.msg
            break
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Set New Password Method
const postNewPassword = data => {
  return axios
    .post(baseUrl + url.POST_LOGIN_DETAILS + '/change-password', data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            message = err.response.data.msg
            break
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}


export {
  postLoginDetails,
  postRegisterDetails,
  postResetPasswordEmail,
  postNewPassword
}