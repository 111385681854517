import {
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  setPasswordSuccessMsg: null,
  error: null,
}

const setPassword = (state = initialState, action) => {
  switch (action.type) {
    case SET_PASSWORD:
      state = {
        ...state,
        setPasswordSuccessMsg: null,
        error: null,
      }
      break
    case SET_PASSWORD_SUCCESS:
      state = {
        ...state,
        setPasswordSuccessMsg: action.payload,
      }
      break
    case SET_PASSWORD_ERROR:
      state = { ...state, error: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default setPassword
