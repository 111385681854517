import PropTypes from 'prop-types'
import React , {useState,useEffect} from "react"
import { 
    Modal, 
    ModalBody ,  
    Alert
} from "reactstrap"
import { Formiz, useForm } from '@formiz/core'
import { InputField } from "../../components/forms/InputField.js";
import { TextAreaField } from '../forms/TextAreaField.js';
import { LoadingIndicator } from "../../components/Common";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useCompanyInventory } from '../../hooks/inventoryHook.js';
import { isEmpty } from 'lodash';
import { AssignItemModal } from './AssignItemModal.js';
import { useProjectDetails } from '../../hooks/inventoryHook.js';
import { MoveItemModal } from './MoveItemModal.js';

export function EditInventoryModal({isopenEditInventory,onCloseClick,data}){
    const inventoryForm = useForm()
    const {isLoading,assignInventory,isAssignLoading} = useCompanyInventory(onCloseClick)
    const [isOpenAssign,setOpenAssign] = useState(false)
    const [assignData,setAssignData] = useState({})
    const {projectDetails} = useProjectDetails()
    const [inventoryData,setInventoryData] = useState({})
    const [isOpenMoveItem,setOpenMoveItem] = useState(false)

    // console.log("inventoryForm",inventoryForm.values)

    const handleOncloseClick = () => {
      onCloseClick()
      setOpenAssign(false)
      setOpenMoveItem(false)
    }

    const handleSubmit = async (values) => {
      
      const result = {
        ...values,
        // ...assignData,
        _id: inventoryData._id,
      }

      // if(!isEmpty(result?.projectId)) {
      //   result["status"] = "Assigned"
      // }

      try {
        await assignInventory(result)
      } catch (error){
        console.log("error" , error)
      }

      // console.log("result",result)
      
    }

    // const handleAssignItem = (data) => {
    //    const item = {
    //      ...data,
    //      ...inventoryForm.values
    //    }
    //     setAssignData(item)
    // }

    const handleInventoryAssignment = () => {
      const item = {
        ...data,
        ...inventoryForm.values
      }
      // console.log("item",item)
      setAssignData(item)
      if(isEmpty(data?.projectId)){
        setOpenAssign(true)
      } else {
        setOpenMoveItem(true)
      }

    }

    const projectSites = projectDetails?.map((project) => {
      return {
        label: project.projectName,
        value: project._id
      }
    } )

    const uniqueCollaborators = [];
    const collabs = {}


    const handleProjectDetails = () => {
      if(!isEmpty(projectDetails)){
                
        const collaboratorsArr = projectDetails?.map(project => project.projectCollaborators)
        const flatCollaborators = collaboratorsArr.flat()

        flatCollaborators.forEach(collaborator => {
           if(!collabs[collaborator.memberEmail]){
            collabs[collaborator.memberEmail] = true
            uniqueCollaborators.push({label: collaborator.memberEmail,value: collaborator._id})
           }
        })
       

      }
    }
    
    handleProjectDetails()

    const getProjectSite = (projectId) => {
        if(!isEmpty(projectId)){
             const result = projectSites.find(item => item.value === projectId)

             if(!isEmpty(result)){
               return result["label"]
             }

             return "N/A"
             
        }else {
          return "N/A"
        }
    }

    useEffect(() => {
      setInventoryData(data)
    }, [data])
    

    return (
    <> 
      <Modal
        isOpen={isopenEditInventory}
        toggle={onCloseClick}
        centered={true}
        size={"lg"}
      >
        <Formiz
          connect={inventoryForm}
          onValidSubmit={handleSubmit}
          initialValues={data}
        >
          <form
            className="w-100 needs-validation"
            noValidate
            onSubmit={inventoryForm.submit}
          >
            {/* <h3>Add Inventory</h3> */}
            <div className="d-flex flex-column w-100 p-4">
              <h3>{data?.inventoryName}</h3>
              <div className="d-flex flex-row w-100 mb-2">
                 <div className="col-6 p-2">
                     <div className="d-flex w-100 mb-2 p-2" style={{background:"#F2F7F9" , borderRadius: "8px"}}>
                         <div>
                             <h6 className="mb-0">Status: <span style={{color: inventoryData.status === "Available" ?  "#32D583" : "#FA7066" , fontWeight: 500}}>{data?.status}</span></h6>
                         </div>
                     </div>
                     <div className="d-flex w-100 mb-2 p-2" style={{background:"#F2F7F9" , borderRadius: "8px"}}>
                         <div>
                             <h6 className="mb-0">Project Site: <span style={{ fontWeight: 700}}>{isEmpty(inventoryData?.projectId) ? "N/A" : getProjectSite(inventoryData?.projectId)}</span></h6>
                         </div>
                     </div>
                 </div>
                 <div className='col-6 p-2'></div>
              </div>
              <div className="d-flex flex-row w-100">
                <div className="col-6 p-2">
                  {/* <div className="w-100  mb-3">
                    <h6 className="mb-1">Item name</h6>
                    <InputField
                      name={"inventoryName"}
                      required={"please enter an item name"}
                      type="text"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Item type</h6>
                    <SelectField
                      name={"inventoryType"}
                      required={"please select an item type"}
                      data={[
                        { label: "Equipment", value: "Equipment" },
                        { label: "Consummables", value: "Consummables" },
                      ]}
                      defaultOption="Select an item type"
                    />
                  </div> */}
                  <div className="w-100  mb-2">
                    <h6 className="mb-1">Serial number</h6>
                    <InputField
                      name={"serialNumber"}
                      required="please enter a serial number"
                      type="text"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-2">
                    <h6 className="mb-1">Model number</h6>
                    <InputField
                      name={"modelNumber"}
                      required={"Please enter a model number"}
                      type="text"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-2">
                    <h6 className="mb-1">Manufacturer</h6>
                    <InputField
                      name={"manufacturer"}
                      required={"Please enter a manufacturer's name"}
                      type="text"
                      placeholder="e.g. Zoomlion,Yukon,Tronic, etc."
                      isDefault={false}
                    />
                  </div>
                </div>
                <div className="col-6 p-2">
                  {/* <div className="w-100  mb-3">
                    <h6 className="mb-1">Status</h6>
                    <SelectField
                      name={"status"}
                      required={"please select a status"}
                      data={[
                        { label: "Available", value: true },
                        { label: "Not Available", value: false },
                      ]}
                      defaultOption="Select a status"
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Total quantity</h6>
                    <InputField
                      name={"total"}
                      required={"Please enter total quantity"}
                      type="number"
                      placeholder=""
                      isDefault={false}
                    />
                  </div> */}
                  {/* <div className="w-100  mb-3">
                    <h6 className="mb-1">Entry date</h6>
                    <InputField
                      name={"entryDate"}
                      required={"Please select an entry date"}
                      type="date"
                      placeholder=""
                      isDefault={false}
                    />
                  </div> */}
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Description</h6>
                    <TextAreaField
                      name={"description"}
                      required={"please add a description"}
                      type="text"
                      placeholder={"Enter a description"}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row w-100 gap-3">
                <div className="col-6 p-2">
                    <div className="d-flex w-100">
                        <a 
                        onClick={handleInventoryAssignment}
                        style={{color: "#194973" , fontWeight: "bold" , textDecoration: "underline !important" , cursor: "pointer"}}>
                            {
                                isEmpty(data?.projectId) ? "Assign this item to a project" : "Move this item to another project"
                            }
                        </a>
                    </div>
                </div>
                <div className="col-6 p-2">
                  <div className="d-flex flex-row gap-3 justify-content-end p-2">
                    <button className="btn btn-primary mb-3" type="submit">
                      {isAssignLoading ? <LoadingIndicator /> : "Save"}
                    </button>
                    <a
                      className="align-self-center"
                      onClick={onCloseClick}
                      style={{
                        textDecoration: "underline !important",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Formiz>
        <ToastContainer />
        <AssignItemModal 
            isopenAssignItem={isOpenAssign} 
            onCloseClick={handleOncloseClick} 
            assignItem={assignData}
            // handleItem={handleAssignItem}
            projectSites={projectSites}
            uniqueCollaborators={uniqueCollaborators}
            />
        <MoveItemModal 
            isopenMoveItem={isOpenMoveItem}
            onCloseClick={handleOncloseClick}
            assignItem={assignData}
            // handleItem={handleAssignItem}
            projectSites={projectSites}
            uniqueCollaborators={uniqueCollaborators}
         />    
      </Modal>
       
    </>   
    );
}

EditInventoryModal.propTypes = {
    onCloseClick: PropTypes.func,
    isopenEditInventory: PropTypes.bool,
    // projectId: PropTypes.string,
    // taskType: PropTypes.number,
    data: PropTypes.object

}