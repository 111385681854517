import PropTypes from 'prop-types'
import React , {useState,useEffect} from "react"
import { 
    Modal, 
    ModalBody ,  
    Alert
} from "reactstrap"
import { Formiz, useForm } from '@formiz/core'
import { InputField } from "../../components/forms/InputField.js";
import { SelectField } from '../forms/SelectField.js';
import { TextAreaField } from '../forms/TextAreaField.js';
import { CalendarField } from '../forms/CalendarField.js';
import { LoadingIndicator } from "../../components/Common";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useCompanyInventory } from '../../hooks/inventoryHook.js';

export function InventoryModal({isopenInventory,onCloseClick}){
    const inventoryForm = useForm()
    const {isLoading,addInventory} = useCompanyInventory(onCloseClick)

    const handleSubmit = async (values) => {
       const userId = JSON.parse(localStorage.getItem('authUser')).user.id       
       const results = {
        ...values,
        userId: JSON.parse(localStorage.getItem('authUser')).user.id
       }
       try {
         await addInventory(results)
         
      
       } catch (error){
         console.log("error" , error)
       }
    }

    return (
      <Modal
        isOpen={isopenInventory}
        toggle={onCloseClick}
        centered={true}
        size={"xl"}
      >
        <Formiz
          connect={inventoryForm}
          onValidSubmit={handleSubmit}
          
        >
          <form 
             className="w-100 needs-validation" 
             noValidate
             onSubmit={inventoryForm.submit}
             >
            {/* <h3>Add Inventory</h3> */}
            <div className="d-flex flex-column w-100 p-4">
              <h3>Add Inventory</h3>
              <div className="d-flex flex-row w-100">
                <div className="col-6 p-2">
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Item name</h6>
                    <InputField
                      name={"inventoryName"}
                      required={"please enter an item name"}
                      type="text"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Item type</h6>
                    <SelectField
                      name={"inventoryType"}
                      required={"please select an item type"}
                      data={[
                        { label: "Equipment", value: "Equipment" },
                        { label: "Consummables", value: "Consummables" },
                      ]}
                      defaultOption="Select an item type"
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Serial number</h6>
                    <InputField
                      name={"serialNumber"}
                      required="please enter a serial number"
                      type="text"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Manufacturer</h6>
                    <InputField
                      name={"manufacturer"}
                      required={"Please enter a manufacturer's name"}
                      type="text"
                      placeholder="e.g. Zoomlion,Yukon,Tronic, etc."
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Description</h6>
                    <TextAreaField
                      name={"description"}
                      required={"please add a description"}
                      type="text"
                      placeholder={"Enter a description"}
                    />
                  </div>
                </div>
                <div className="col-6 p-2">
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Model number</h6>
                    <InputField
                      name={"modelNumber"}
                      required={"Please enter a model number"}
                      type="text"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Status</h6>
                    <SelectField
                      name={"status"}
                      required={"please select a status"}
                      data={[
                        { label: "Available", value: "Available" },
                        { label: "Not Available", value: "Not Available" },
                      ]}
                      defaultOption="Select a status"
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Total quantity</h6>
                    <InputField
                      name={"total"}
                      required={"Please enter total quantity"}
                      type="number"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Entry date</h6>
                    <InputField
                      name={"entryDate"}
                      required={"Please select an entry date"}
                      type="date"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-end gap-3">
                <button className="btn btn-primary mb-3" type="submit">
                    {
                       isLoading ? <LoadingIndicator /> : "Save"
                    }
                </button>
                <a
                  className="align-self-center"
                  onClick={onCloseClick}
                  style={{
                    textDecoration: "underline !important",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </a>
              </div>
            </div>
          </form>
        </Formiz>
        <ToastContainer />
      </Modal>
    );
}

InventoryModal.propTypes = {
    onCloseClick: PropTypes.func,
    isopenInventory: PropTypes.bool,
    // projectId: PropTypes.string,
    // taskType: PropTypes.number,
    // data: PropTypes.object

}