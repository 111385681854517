import React, { useState, useEffect , useRef } from "react"
import { Alert, Button, Card, CardHeader, CardBody, Col, Form, Label, Input, Row, Table, Modal } from "reactstrap";

import * as url from "../../helpers/jengasmart_api_urls";

import axios from 'axios';
import { map } from "lodash";

import {LoadingIndicator} from '../../components/Common'
import DeleteModal from "../../components/Common/DeleteModal";


const Materials = (props) => {
  const [materialsModal, setMaterialsModal] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState(false);
  const [listError, setListError] = useState(false);
  const [materialName, setMaterialName] = useState('');
  const [unitOfMeasurement, setUnitOfMeasurement] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [supplier, setSupplier] = useState('');
  const [description, setDescription] = useState('');
  const [isSaving,setSaving] = useState(false)
  const [isShowDelete , setshowDelete] = useState(false)
  const [deleteItem,setDeleteItem] = useState("")
  const [isDeleting,setDeleting] = useState(false)
  const [isEditing,setEditing] = useState(false)
  const [material,setMaterial] = useState({})


  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
    }
  };
  
  const api_url = process.env.REACT_APP_API_BASE + url.POST_MATERIAL_DETAILS + '/project/' + props.projectId;

  const DELETE_MATERIAL_URL = process.env.REACT_APP_API_BASE + "materials/";
  
  const EDIT_MATERIALS_URL = process.env.REACT_APP_API_BASE + "materials/"

  function tog_materialsModal() {
    setMaterialsModal(!materialsModal);
  }

  const fetchMaterials = () => {
    axios.get(api_url, config)
    .then(res => {
      setList(res.data.data);
    })
    .catch(error => {
      if (error.response) {
        if(error.response.status === 401 || error.response.status === 403) {
            props.history.push('/login');
        }
        else {
            setError(error.response.data.error[0]);
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
    });
  }

  useEffect(() => {
      
    fetchMaterials()
      
    }, []);
    

  function addMaterial(e) {
    e.preventDefault();

    var config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
      }
    };
    
    var data = {
      projectId: props.projectId,
      materialName: materialName,
      unitOfMeasurement: unitOfMeasurement,
      price: price,
      quantity: quantity,
      supplier: supplier,
      description: description,
    };
    
    var api_url = process.env.REACT_APP_API_BASE + url.POST_MATERIAL_DETAILS;
    
    setSaving(true)
    axios.post(api_url, data, config)
    .then(res => {
      var newItemArr = [];

      newItemArr.push(res.data.data);
      var newListArr = newItemArr.concat(list);
      setList(newListArr);
      setMaterialsModal(false);
      setMaterialName('');
      setUnitOfMeasurement('');
      setPrice('');
      setQuantity('');
      setSupplier('');
      setDescription('');
      setSaving(false)
    })
    .catch(error => {
      setSaving(false)
      if (error.response) {
          if(error.response.status === 401 || error.response.status === 403) {
              props.history.push('/login');
          }
          else {
              setError(error.response.data.error[0]);
          }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
    });
  }

  const handleDeleteMaterial = async () => {
    setDeleting(true)
   await axios.delete(DELETE_MATERIAL_URL + deleteItem , config)
      .then((data) => {
        setDeleting(false)
        fetchMaterials()
        setshowDelete(false)
    })
   
    
  }

  const handleShowDelete = (id) => {
    setDeleteItem("")
    setshowDelete(true)
    setDeleteItem(id)
  }

  // Editing
  const handleEditing = (material) => {
      setEditing(true)
      setMaterial(material)
  }

  const handleMaterialSubmit = async (e) => {
    e.preventDefault();
    setSaving(true)
    const data = new FormData(e.target)
    const materials = {
      projectId: props.projectId,
      materialName: data.get("materialName"),
      unitOfMeasurement: data.get("unitOfMeasurement"),
      price: data.get("price"),
      quantity: data.get("quantity"),
      supplier: data.get("supplier"),
      description: data.get("description"),
      matUniqueIdentifier: material.matUniqueIdentifier
    };

    await axios.patch(EDIT_MATERIALS_URL + material._id ,materials , config)
      .then((data) => {
        setSaving(false)
        fetchMaterials()
        setEditing(false)
    }).catch((error) => {
      setSaving(false)
    })

  }



  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader>
            <Row>
              <Col xl={6} md={6} xs={12}>
                <h4 className="card-title">List of Materials</h4>
                <p className="card-title-desc mb-3">
                  Track all the materials and cost of each item
                </p>
              </Col>
              <Col xl={6} md={6} xs={12} style={{ textAlign: "right" }}>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    tog_materialsModal();
                  }}
                >
                  Add New
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Measurement (kg, etc)</th>
                    <th>Quantity</th>
                    <th>Cost</th>
                    <th>Supplier</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {listError || list.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No materials added
                      </td>
                    </tr>
                  ) : (
                    <>
                      {map(list, (item, key) => (
                        <tr key={key}>
                          <th scope="row">{key + 1}</th>
                          <td>{item.materialName}</td>
                          <td>{item.unitOfMeasurement}</td>
                          <td>{item.quantity}</td>
                          <td>{props.currency + " " + item.price}</td>
                          <td>{item.supplier}</td>
                          <td>
                            <div className="d-flex flex-row gap-3">
                              <a onClick={() => handleEditing(item)}>
                                <svg
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  height="1.5em"
                                  width="1.5em"
                                >
                                  <path d="M14.06 9l.94.94L5.92 19H5v-.92L14.06 9m3.6-6c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29m-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
                                </svg>
                              </a>
                              <a onClick={() => handleShowDelete(item._id)}>
                                <svg
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                  height="1em"
                                  width="1em"
                                >
                                  <path d="M6.5 1h3a.5.5 0 01.5.5v1H6v-1a.5.5 0 01.5-.5zM11 2.5v-1A1.5 1.5 0 009.5 0h-3A1.5 1.5 0 005 1.5v1H2.506a.58.58 0 00-.01 0H1.5a.5.5 0 000 1h.538l.853 10.66A2 2 0 004.885 16h6.23a2 2 0 001.994-1.84l.853-10.66h.538a.5.5 0 000-1h-.995a.59.59 0 00-.01 0H11zm1.958 1l-.846 10.58a1 1 0 01-.997.92h-6.23a1 1 0 01-.997-.92L3.042 3.5h9.916zm-7.487 1a.5.5 0 01.528.47l.5 8.5a.5.5 0 01-.998.06L5 5.03a.5.5 0 01.47-.53zm5.058 0a.5.5 0 01.47.53l-.5 8.5a.5.5 0 11-.998-.06l.5-8.5a.5.5 0 01.528-.47zM8 4.5a.5.5 0 01.5.5v8.5a.5.5 0 01-1 0V5a.5.5 0 01.5-.5z" />
                                </svg>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            <Modal
              isOpen={isEditing}
              toggle={() => {
                setEditing(!isEditing)
              }}
            >
              <Form  onSubmit={(e) => handleMaterialSubmit(e)}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Edit Materials
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setEditing(!isEditing);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <Label htmlFor="material-name" className="form-Label">
                      Item
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Cement"
                      name="materialName"
                      defaultValue={material?.materialName}
                      // onChange={(e) => setMaterialName(e.target.value)}
                      id="material-name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="uom" className="form-Label">
                      Unit of Measurement
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="kg, litres, etc"
                      name="unitOfMeasurement"
                      defaultValue={material?.unitOfMeasurement}
                      // onChange={(e) => setUnitOfMeasurement(e.target.value)}
                      id="uom"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="quantity" className="form-Label">
                      Quantity
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="5"
                      name="quantity"
                      defaultValue={material?.quantity}
                      // onChange={(e) => setQuantity(e.target.value)}
                      id="quantity"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="price" className="form-Label">
                      Cost Per Unit
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="5000"
                      name="price"
                      defaultValue={material?.price}
                      // onChange={(e) => setPrice(e.target.value)}
                      id="price"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="supplier" className="form-Label">
                      Supplier
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Supplier's name"
                      name="supplier"
                      defaultValue={material?.supplier}
                      // onChange={(e) => setSupplier(e.target.value)}
                      id="supplier"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label
                      htmlFor="basicpill-description-input"
                      className="form-Label"
                    >
                      Description (Optional)
                    </Label>

                    <Input
                      name="description"
                      className="form-control"
                      type="textarea"
                      id="basicpill-description-input"
                      placeholder="Needed for building foundation of building"
                      defaultValue={material?.description}
                      // onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    {error ? <Alert color="danger">{error}</Alert> : null}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      setEditing(!isEditing);
                    }}
                    className="btn btn-secondary "
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    disabled={isSaving}
                  >
                    {isSaving ? <LoadingIndicator /> : <span>Save</span>}
                  </button>
                </div>
              </Form>
            </Modal>
            <DeleteModal show={isShowDelete} onCloseClick={() => setshowDelete(false)} onDeleteClick={() => handleDeleteMaterial()} isLoading={isDeleting} />
           {/* Add materials */}
            <Modal
              isOpen={materialsModal}
              toggle={() => {
                tog_materialsModal();
              }}
            >
              <Form onSubmit={(e) => addMaterial(e)}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Adding Materials
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setMaterialsModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <Label htmlFor="material-name" className="form-Label">
                      Item
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Cement"
                      defaultValue={materialName}
                      onChange={(e) => setMaterialName(e.target.value)}
                      id="material-name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="uom" className="form-Label">
                      Unit of Measurement
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="kg, litres, etc"
                      defaultValue={unitOfMeasurement}
                      onChange={(e) => setUnitOfMeasurement(e.target.value)}
                      id="uom"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="quantity" className="form-Label">
                      Quantity
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="5"
                      defaultValue={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      id="quantity"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="price" className="form-Label">
                      Cost Per Unit
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="5000"
                      defaultValue={price}
                      onChange={(e) => setPrice(e.target.value)}
                      id="price"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="supplier" className="form-Label">
                      Supplier
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Acme Inc."
                      defaultValue={supplier}
                      onChange={(e) => setSupplier(e.target.value)}
                      id="supplier"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Label
                      htmlFor="basicpill-description-input"
                      className="form-Label"
                    >
                      Description (Optional)
                    </Label>

                    <Input
                      name="description"
                      className="form-control"
                      type="textarea"
                      id="basicpill-description-input"
                      placeholder="Needed for building foundation of building"
                      defaultValue={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    {error ? <Alert color="danger">{error}</Alert> : null}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      tog_materialsModal();
                    }}
                    className="btn btn-secondary "
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    disabled={isSaving}
                  >
                    {isSaving ? <LoadingIndicator /> : <span>Add</span>}
                  </button>
                </div>
              </Form>
            </Modal>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default Materials
