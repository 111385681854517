import React from 'react'
import {useField} from '@formiz/core'

export const SelectField = (props) => {
   const {setValue,value , errorMessage , isValid , isSubmitted , id } = useField(props)
   const { label,  required , placeholder , data , name , isStatus , defaultOption} = props
   const [isTouched, setIsTouched] = React.useState(false)
   const showError = !isValid && (isTouched || isSubmitted)
   

   return (
       
    <div className="row  d-flex align-items-center">
      <div className="col-md-12">
          <div>
              <select
                  className={`form-control ${(showError) ? 'is-invalid' : ''}`}
                  id={id}
                  value={value ?? ''}
                  onChange={e => setValue(e.target.value)}
                  onBlur={() => setIsTouched(true)}
                  aria-invalid={showError}
                  aria-required={!!required}
                  aria-describedby={showError ? `${id}-error` : null}
              >
                  <option>{defaultOption}</option>
                  {data.map((item, key) => (
                      <option value={item.value} key={key}>{item.label}</option>
                  ))}
              </select>


              {showError && (
                  <div id={`${id}-error`} className="invalid-feedback d-block">
                      { errorMessage }
                  </div>
              )}
          </div>

      </div>
    </div>
                
        
   )
}