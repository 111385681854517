import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col,  Row , Table , Button , CardHeader} from "reactstrap";

export function CrossSiteInventory(){
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            {/* <Breadcrumbs title="Inventory" breadcrumbItem="Company Inventory" /> */}

            <div className="d-flex flex-column p-3 w-100">
              <div className="d-flex justify-content-between my-3">
                <h3>Cross-site Logistics</h3>
                <Button
                  type="button"
                  color="primary"
                  //   onClick={() => {
                  //     tog_materialsModal();
                  //   }}
                >
                  Assign item
                </Button>
              </div>
              
              <div className="w-100 my-3">
                <div >
                  <Card>
                   
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Borrow Date</th>
                              <th>Return Date</th>
                              <th>Assignee</th>
                              <th>Item</th>
                              <th>Quantity</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            
                              <tr>
                                 
                              </tr>
                              
                            
                          </tbody>
                        </Table>
                      </div>
                      
                      
                    </CardBody>
                  </Card>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}