import React, { useState, useEffect } from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as url from "../../helpers/jengasmart_api_urls";
import axios from "axios";
const Reports = (props) => {
    //meta title
    document.title = "Reports - Projects | Jenga App";

    const [projectError, setProjectError] = useState('');
    const [projects, setProjects] = useState([]);
    const [isLoading , setIsLoading] = useState(false)

    // Get project details
    const getProjectList = async ()=>{
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
            }
        };

        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/user/' + JSON.parse(localStorage.getItem('authUser')).user.email;
        setIsLoading(true)
        try {
            const projects = await axios.get(api_url, config)
            console.log(projects.data.data)
            setProjects(projects.data.data);
            setIsLoading(false)
        } catch (error) {
            if (error.response) {
                if(error.response.status === 401 || error.response.status === 403) {
                    props.history.push('/login');
                }
                else {
                    setProjectError(error.response.data.error[0]);
                }
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            setIsLoading(false)
        }


    }
    const handleViewProject = async (id)=>{
        window.location.assign(`/reports/${id}`)
    }
    useEffect(() => {
      getProjectList()
    }, []);
    return (
        <>
            <div className="page-content">
                <div className={"container-fluid px-3"}>
                <Container fluid id="basic-pills-wizard" className="twitter-bs-wizard">
                    <Breadcrumbs title="Reports" breadcrumbItem="Reports" />
                    <div className="w-100 reports-list" >

                            <Card style={{background:"#F2F7F9"}}>

                                <CardBody>
                                    <h4 className="card-title">Select Project</h4>
                                    <div className="table-responsive mt-2">
                                        {
                                            projects.length > 0 ? <>
                                                <Table className="table mb-0">
                                                    <thead>
                                                    <tr>
                                                        <th>Project Name</th>
                                                        <th>Date Added</th>
                                                        <th>Progress</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        projects.map((project, key)=>(
                                                            <tr key={key} onClick={()=>handleViewProject(project._id)}>
                                                                <td>{project.projectName}</td>

                                                                <td>{new Date(project.createdAt).toLocaleDateString()}</td>
                                                                <td>{project.progress} %</td>
                                                                <td></td>
                                                            </tr>
                                                        ))
                                                    }



                                                    </tbody>
                                                </Table>
                                            </> : <>
                                            <p>No projects added yet</p>
                                            </>
                                        }

                                    </div>


                                </CardBody>
                            </Card>


                    </div>
                </Container>
                </div>
            </div>
        </>
    )
}
export default Reports