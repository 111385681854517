import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

const OnboardingNewProject = () => {
    return (
        <React.Fragment>
            <div className="my-5 pt-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mb-5">
                                <h1 className="display-1 fw-semibold">Welcome, <span className="text-primary mx-2">Kwame</span></h1>
                                <h4 className="mb-5">Begin by adding a new project</h4>
                                

                                <div className="form-floating mt-5 mb-3 mb-lg-0">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                  />
                                  <label htmlFor="floatingInput">Project Name</label>

                                  <div className="mt-5 text-center">
                                    <Link className="btn btn-primary" to="/onboarding-new-member">Next</Link>
                                  </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default OnboardingNewProject;