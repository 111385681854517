import React , {useState,useEffect} from "react"
import { useQuery , useQueryClient , useMutation  } from "@tanstack/react-query";
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from 'lodash';
import * as url from "../helpers/jengasmart_api_urls";

// const queryClient = useQueryClient()




const CREATE_INVENTORY_URL = process.env.REACT_APP_API_BASE + "inventory"  
const FETCH_INVENTORY_URL = process.env.REACT_APP_API_BASE + "inventory"
const DELETE_INVENTORY_URL = process.env.REACT_APP_API_BASE + "inventory/"
const COLLABORATORS_URL = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + `/user/` 
// const config = {
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
//     }
//   };



export const useCompanyInventory = (onCloseClick) => {
    const queryClient = useQueryClient();
    let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
      };

    const { data: companyInventory   } = useQuery({
        queryKey: ['comInventory'],
        initialData: [],
        queryFn: async () => {
            const {data} = await axios.get(FETCH_INVENTORY_URL , config)
            const results = data;
       
            return results.data
        }
    })

    const addCInventory = async (data) => {
        return await axios.post(CREATE_INVENTORY_URL,data,config)
    }
    

    const updateInventory = async (data) => {
        return await axios.patch(FETCH_INVENTORY_URL + "/" + data._id, data, config);
      };
    
    const deleteCInventory = async (item) => {
         return await axios.delete(DELETE_INVENTORY_URL + item,config)
    }
       
    const {mutateAsync: addInventory ,isLoading} = useMutation({
        mutationFn: addCInventory,
        onSuccess: (data) => {
            queryClient.invalidateQueries(['comInventory']) 
            toast.success("Inventory added successfully", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "comInventory"
                          });
              
            if(!isEmpty(data)) onCloseClick()       
             },
        onError: (error) => {
            console.log("company inventory error",error)
            }
    })

    const {mutateAsync: assignInventory ,isAssignLoading} = useMutation({
        mutationFn: updateInventory,
        onSuccess: (data) => {
            queryClient.invalidateQueries(['comInventory']) 
            toast.success("Inventory updated successfully", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "updateInventory"
                          });
              
             onCloseClick()       
             },
        onError: (error) => {
            console.log("company inventory error",error)
            }
    })

    const {mutateAsync: deleteInventory ,isDeleteLoading} = useMutation({
        mutationFn: deleteCInventory,
        onSuccess: (data) => {
            if(!isEmpty(data)) onCloseClick() 
            queryClient.invalidateQueries(['comInventory']) 
            toast.success("Inventory deleted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                toastId: "deleteInventory"
                          });
             },
        onError: (error) => {
            console.log("company inventory error",error)
            }
    })



    return {
        addInventory,
        isLoading,
        companyInventory,
        deleteInventory,
        isDeleteLoading,
        assignInventory,
        isAssignLoading
        
    }
}



export const useProjectDetails = () => {
    const queryClient = useQueryClient();
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
      };

      const fetchAssignees =  async function fetchCollaborators() {
        const email = JSON.parse(localStorage.getItem('authUser')).user?.email
        const { data: results } = await axios.get(COLLABORATORS_URL + email, config);
        return results.data;
      }

    const { data: projectDetails = [] } = useQuery({
        queryKey: ['fetch_assignee'],
        queryFn: fetchAssignees,
        initialData: [],
        onSuccess: (data) => {
        //   const assignees = data.map((item) => {
        //        return {label: item.memberEmail , value: item._id}
        //     })
    
        }
      });

      return {projectDetails}
}

