import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Progress, Row } from "reactstrap";

//import images
import img1 from "../../assets/images/small/img-1.jpg";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import * as url from "../../helpers/jengasmart_api_urls";

import axios from 'axios';
import { map } from "lodash";

import {FullPageLoadingIndicator} from '../../components/Common'


const Projects = (props) => {
    //meta title
    document.title = "Projects | Jenga App";

    const [projectError, setProjectError] = useState('');
    const [projects, setProjects] = useState([]);
    const [isLoading , setIsLoading] = useState(false)

    useEffect(() => {
        var config = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
            }
          };
          
          var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/user/' + JSON.parse(localStorage.getItem('authUser')).user.email;
          
          setIsLoading(true)
          axios.get(api_url, config)
          .then(res => {
            setProjects(res.data.data);
          })
          .catch(error => {
            if (error.response) {
                if(error.response.status === 401 || error.response.status === 403) {
                    props.history.push('/login');
                }
                else {
                    setProjectError(error.response.data.error[0]);
                }
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
          }).finally(() => setIsLoading(false) );
      }, []);

    return (
        <React.Fragment>
            <div className="page-content" style={{paddingBottom:"2%"}}>
                <div className={"container-fluid px-3"}>
                    <Container fluid>
                    {/* Render Breadcrumbs */}
                    
                    {
                        isLoading ? (
                            <FullPageLoadingIndicator />
                            )
                            : (
                               <>
                                  <Breadcrumbs title="Dashboard" breadcrumbItem="Projects" />
                    <Row>

                        {map(projects, (item, key) => (
                            <Col md={3} xl={3} sm={3} key={key} className="mb-5 ">
                                <Link to={`/dashboard/${item._id}` }>
                                    <Card style={{ marginBottom: 0, borderRadius:"8px", boxShadow: ["0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 12px 16px -4px rgba(0, 0, 0, 0.1)"]
                                    }}>
                                        {
                                            item.image.length > 0 ?(
                                                <img className="card-img-top img-fluid" src={item.image[0]} alt="" style={{ height: 200, objectFit: 'cover' }} />
                                            ) : (
                                                <img className="card-img-top img-fluid" src={img1} alt="" style={{ height: 200, objectFit: 'cover' }} />
                                            )
                                        }
                                        <CardBody>
                                            <h4 className="card-title" style={{fontSize:"14px", textTransform:"capitalize"}}>{item.projectName}</h4>
                                            <h6 className={"project-dets"}>Created By: <span>{item.projectCollaborators[0].memberEmail}</span></h6>
                                            <h6 className={"project-dets"}>Start Date: <span>{new Date(item.startDate).toLocaleDateString()}</span></h6>
                                            <h6 className={"project-dets"}>End Date: <span>{new Date(item.endDate).toLocaleDateString()}</span></h6>
                                            <Progress
                                                value={item.progress}
                                                color="primary"
                                                className="progress-md text-end list-progress"
                                            >
                                                <span>{item.progress.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}%</span>
                                            </Progress>
                                        </CardBody>

                                    </Card>
                                </Link>
                            </Col>
                        ))}
                        <Col md={3} xl={3} className="mb-3">
                            <Link to="/new-project">
                                <Card className=" text-white-50 text-center " style={{ height: '85%', background:"#F0F8FC", minHeight:"350px",  boxShadow: ["0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 12px 16px -4px rgba(0, 0, 0, 0.1)"] }}>
                                    <CardBody className={"d-flex align-items-center justify-content-center"}>
                                        <div>
                                            <i className="fas fa-plus" style={{ fontSize: '14px', color:"#22282F" }}></i>
                                            <h5 className="mb-3" style={{ marginTop: '20px', color:"#194973" }}>Add New</h5>
                                        </div>
                                    </CardBody>

                                </Card>
                            </Link>
                        </Col>
                    </Row>
                               </> 
                            )
                    }

                   
                </Container>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Projects;