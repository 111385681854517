import React , {useState,useEffect} from "react"
import { useQuery , useQueryClient , useMutation  } from "@tanstack/react-query";
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from 'lodash';
import * as url from "../helpers/jengasmart_api_urls";

const ADD_EQUIPMENT = process.env.REACT_APP_API_BASE + url.POST_EQUIPMENT_DETAILS;
const EQUIPMENT_URL = process.env.REACT_APP_API_BASE + "equipments/";
const GET_EQUIPMENTS = process.env.REACT_APP_API_BASE + url.POST_EQUIPMENT_DETAILS + '/project/' ;



export const useEquipment = (onCloseClick,projectId) => {
    const queryClient = useQueryClient();
    let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
      };

      const addEquipment = async (data) => {
        return await axios.post(ADD_EQUIPMENT,data,config)
    }
    
    const updateEquipment = async (data) => {
        return await axios.patch(EQUIPMENT_URL + data._id, data, config);
      };
    
    const deleteEquipment = async (id) => {
        await axios.delete(EQUIPMENT_URL + id , config)
    } 

    //   fetch equipment

    const { data: equipments   } = useQuery({
        queryKey: ['fetchEquipment'],
        initialData: [],
        queryFn: async () => {
            const {data} = await axios.get(GET_EQUIPMENTS + projectId  , config)
            const results = data;
       
            return results.data
        }
    })
      
      const {mutateAsync: addInvEquipment ,isEquipmentLoading} = useMutation({
        mutationFn: addEquipment,
        onSuccess: (data) => {
            queryClient.invalidateQueries(['fetchEquipment']) 
            toast.success("Equipment added successfully", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "addEquip"
                          });
              
            if(!isEmpty(data)) onCloseClick()       
             },
        onError: (error) => {
            console.log("equipment error",error)
            }
    })
    
    const {mutateAsync: updateInvEquipment ,isEquipmentUpdateLoading} = useMutation({
        mutationFn: updateEquipment,
        onSuccess: (data) => {
            queryClient.invalidateQueries(['fetchEquipment']) 
            toast.success("Equipment updated successfully", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "upEquipment"
                          });
              
            if(!isEmpty(data)) onCloseClick()       
             },
        onError: (error) => {
            console.log("equipment error",error)
            }
    })

    const {mutateAsync: deleteInvEquipment ,isdeleteLoading} = useMutation({
        mutationFn: deleteEquipment,
        onSuccess: (data) => {
            queryClient.invalidateQueries(['fetchEquipment']) 
            toast.success("Equipment Deleted", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "upEquipment"
                          });
              
           onCloseClick()       
             },
        onError: (error) => {
            console.log("equipment error",error)
            }
    })

    return {
        addInvEquipment,
        isEquipmentLoading,
        equipments,
        updateInvEquipment,
        isEquipmentUpdateLoading,
        deleteInvEquipment,
        isdeleteLoading
    }
}