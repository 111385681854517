import React, {useState, useEffect, useRef} from "react"
import { useParams, useHistory } from "react-router-dom";
import { Alert, Button, Card, CardHeader, CardBody, Container, Col, Form, Label, Input, Row, Table, Modal } from "reactstrap";

import * as url from "../../../helpers/jengasmart_api_urls";

import axios from 'axios';
import { map } from "lodash"
import moment from 'moment';
import ReactPaginate from "react-paginate";
import DashboardNavigation from "./DashboardNavigation";

const DailyLogs = (props) => {
    const params = useParams();
    const {id} = useParams()
    const history = useHistory();
    const [page, setPage]=useState(1)
    const [list, setList] = useState([]);
    const [error, setError] = useState(false);
    const [listError, setListError] = useState(false);
    const[totalPages, setTotalPages]=useState(0)
    const tableRef = useRef(null);
    const handlePageClick = (event) => {
        setPage(event.selected +1)
    };
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    var api_url = process.env.REACT_APP_API_BASE + url.POST_LOG_DETAILS + '/project/' + id + `?page=${page}`;
    const getLogs = async ()=>{
        try {
            const log = await axios.get(api_url, config)
            setList(log.data.data)
            setTotalPages(log.data.totalPages)
            if (tableRef.current) {
                tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        } catch (e){
            if(e.response.status === 401 || error.response.status === 403) {
                props.history.push('/login');
            }
        }
    }
    useEffect(() => {
        getLogs()

    }, [page]);

    return (
        <>
            <div className="page-content">
            <DashboardNavigation projectTitle={"Daily Logs"} />
                <div className={"container-fluid px-3"}>
                    <Row>
                <Col sm="12" >
                    <div className={"container-fluid p-0"} ref={tableRef}>
                        <Row className={"mt-4"}>
                            <Col xl={6} md={6} xs={12}>
                                <h4 className="card-title">Daily Logs</h4>
                            </Col>
                            <Col xl={6} md={6} xs={12} style={{ textAlign: 'right' }}>
                                <Button
                                    type="button"
                                    className={"add-new"}
                                    onClick={() => {
                                        props.history.push(`/add-progress/${params.id}?projectname=${props.projectName}`);
                                    }}>
                                   + Add New
                                </Button>
                            </Col>
                        </Row>
                        <Card className={"mt-4"}>
                            <CardBody className={"p-0"}>
                                <div className="table-responsive" >
                                    <Table className="table mb-0">
                                        <thead>
                                        <tr>
                                            <th>User</th>
                                            <th>Log Date</th>
                                            <th>Total Costs</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            listError || list.length === 0 ? (
                                                <tr>
                                                    <td colSpan={11} className="text-center">No logs added</td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {map(list, (item, key) => (
                                                        <tr key={key} onClick={() => history.push("/view-progress/" + item._id)}>
                                                            <td className={"d-flex align-items-center"}><i className="fas fa-user-circle" style={{ fontSize: '24px', color:"#22282F", marginRight:".5rem" }}></i> {item.userId?.firstName + " " + item.userId?.lastName}</td>
                                                            <td>{moment(item.logDate).format("MMMM-Do-YYYY")} </td>
                                                            <td>KES { item.totalLogAmount}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                    <div className={"container paginate mt-4"}>
                                        <ReactPaginate pageCount={totalPages}
                                                       previousLabel={'Previous'}
                                                       nextLabel={'Next'}
                                                       breakLabel={'...'}
                                                       breakClassName={'break-me'}
                                                       marginPagesDisplayed={1}
                                                       pageRangeDisplayed={10}
                                                       onPageChange={handlePageClick}
                                                       containerClassName={'pagination'}
                                                       subContainerClassName={'pages pagination'}
                                                       pageClassName={"page-item"}
                                                       activeClassName={"active"}
                                        />
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    </div>

                </Col>
            </Row>
                </div>
            </div>
        </>

    )
}

export default DailyLogs
