import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Table
} from "reactstrap";

import * as url from "../../../helpers/jengasmart_api_urls";

import { GanttComponent ,
    ColumnsDirective,
    ColumnDirective ,
    Inject,
    Selection,
    Edit,
    Toolbar,
    Filter
} from "@syncfusion/ej2-react-gantt";

import { Link } from "react-router-dom";

import axios from "axios";

import { useQuery , useQueryClient } from "@tanstack/react-query";
// import moment from 'moment';

import { MilestoneModal } from "../../../components/Modals/MilestoneModal";
import { TaskModal } from "../../../components/Modals/TaskModal";
import { ImportTasksModal } from "../../../components/Modals/ImportTasksModal";

import { projectNewData, mockData, assigneeImages } from "../../../constants/ganttData";
import { isEmpty } from "lodash";
import FeatherIcon from "feather-icons-react";
import DashboardNavigation from "./DashboardNavigation";

const NewSchedule = (props) => {
    const params = useParams();
    const {id} = useParams()
    const [menu, setMenu] = useState(false);
    const [isMilestoneOpen , setOpenMilestone] = useState(false)
    const [isTaskOpen , setOpenTask] = useState(false)
    // const [isparentTask , setParentTask] = useState(true)
    const [taskType , setTypeTask] = useState(0)
    const[taskId, setTaskId]=useState("")
    const[tasksList, setTasksList]=useState([])
    const [editMilestone,setEditMilestone] = useState({isEdit: false , data: {}})
    const [editTask,setEditTask] = useState({isEdit: false , data: {}})
    const [openTask, setOpenTask1] = useState(null); // State to track the open task
    const [openSubTask, setOpenSubTask] = useState(null); // State to track the open sub task
    const [dependencyList , setDependencyList] = useState([])
    const [taskdependencyList , setTaskDependencyList] = useState([])
    const [selected,setSelectedView] = useState("list")
    const [listData,setListData] = useState([])
    const [collaborators,setCollaborators] = useState([])
    const[viewTracker, setViewTracker] = useState(1)//To change view from Gantt to List and vice versa
    const[editType, setEditType] = useState("")

    const[importTasksModal, setImportTasksModal] = useState(false);

    const TASK_URL = process.env.REACT_APP_API_BASE + url.POST_TASKS_DETAILS + "/project/" + id;
    const MILESTONE_URL = process.env.REACT_APP_API_BASE + "milestones/project/" + id + "?page=1";
    const PARENT_TASK_URL = process.env.REACT_APP_API_BASE + "parenttasks/project/" + id
    const SUB_TASK_URL = process.env.REACT_APP_API_BASE +  "subtasks/project/" + id;
    var progress_url =  process.env.REACT_APP_API_BASE + url.GET_STATS + '/tasks/' + id;
    const COLLABORATORS_URL = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + `/collaborators/${id}`

    let config = {
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("authUser")).token,
        },
    };
    const toggleTask = (taskId, edit, editData) => {
        setEditTask({...editTask,isEdit: true , data: {
                ...editData,
            }})
        handleTask(1)
        setOpenTask1(openTask === taskId ? null : taskId); // Toggle the open task
    };
    const toggleSubTask = (subTaskId, edit, editData) => {
        setEditTask({...editTask,isEdit: true , data: {
                ...editData,
            }})
        handleTask(2)
    };
    const toggleSubTask1 = ( edit, editData) => {
        setEditTask({...editTask,isEdit: true , data: {
                ...editData,

            }})
        handleTask(3)
    };
    const getTasks = async ()=>{
        const progressValues = await axios.get(progress_url, config)
        setTasksList(progressValues.data.tasksLists)
    }
    const handleTask = (index) => {
        // setParentTask(isParent)
        parenttaskRefetch()
        subtaskRefetch()
        setOpenTask(true)
        switch (index) {
            case 1:
                //parent task
                setTypeTask(1)
                break;
            case 2:
                //sub task
                setTypeTask(2)
                break;
            case 3:
                //task
                setTypeTask(3)
                break;
            default:
                break;
        }

    }

    function tog_tasksImportModal() {
        setImportTasksModal(!importTasksModal);
    }

    const handleClose = (index) => {

        switch (index) {
            case 0:
                setEditMilestone({...editMilestone , isEdit: false , data: {}})
                setOpenMilestone(!isMilestoneOpen)
                break;
            case 1:
                //parent
                setEditTask({...editTask , isEdit: false , data: {}})
                setOpenTask(!isTaskOpen)
                break;
            case 2:
                //sub task
                setEditTask({...editTask , isEdit: false , data: {}})
                setOpenTask(!isTaskOpen)
                break;
            case 3:
                //task
                setEditTask({...editTask , isEdit: false , data: {}})
                setOpenTask(!isTaskOpen)
                break;

            default:
                break;
        }
    }

    async function fetchTasks(){
        const {data} = await axios.get(TASK_URL,config)
        const results = data;
        return results.data
    }

    //gantt fetch data
    async function fetchProjectMilestones(){
        const {data: results} = await axios.get(MILESTONE_URL,config)
        return results.data;
    }

    async function fetchParentTask(){
        const {data: results} = await axios.get(PARENT_TASK_URL , config)
        return results.data;
    }

    async function fetchSubTask(){
        const {data: results} = await axios.get(SUB_TASK_URL , config)
        return results.data;
    }

    async function fetchCollaborators() {
        const { data: results } = await axios.get(COLLABORATORS_URL, config);
        return results.data;
    }
    const { isLoading, isError, data: tasks, error: taskError } = useQuery({
        queryKey: ['tasks'],
        queryFn: fetchTasks,
        initialData: []
    });

    const resultArray = [];
    const taskResultArray = [];
    const scheduleArray = []

    const extrackMilestoneInfo = (item) => {
        resultArray.push({value: item._id , label: item.taskName})

        return resultArray
    }

    const extrackTaskInfo = (item) => {
        if (!item) {
            return taskResultArray;
        }
    
        taskResultArray.push({ value: item._id, label: item.taskName });
    
        if (item.subtasks) {
            item.subtasks.forEach(subtask => {
                extrackTaskInfo(subtask);
            });
        }
    
        return taskResultArray;
    };

    const extrackScheduleInfo = (item) => {
        if (!item) {
            return scheduleArray;
        }
    
        scheduleArray.push(item);
    
        if (item.subtasks) {
            item.subtasks.forEach(subtask => {
                extrackScheduleInfo(subtask);
            });
        }
    
        return scheduleArray;
    };

    const { data: parentTask , refetch: parenttaskRefetch  } = useQuery({
        queryKey: ['parentTasks'],
        queryFn: fetchParentTask,
        initialData: [],
        onSuccess: (parentTask) => {
            //  console.log("on success parent task" , parentTask)
            const result = parentTask.map((item) => {
                return extrackMilestoneInfo(item)
            })

            const taskResult = parentTask.map((item) => {
                return extrackTaskInfo(item)
            })
            // console.log("result" , ...result)
            setDependencyList(...result)
            setTaskDependencyList(...taskResult)

        }
    });

    // console.log("dependency" , dependencyList)

    const { data: subTask , refetch: subtaskRefetch } = useQuery({
        queryKey: ['subTasks'],
        queryFn: fetchSubTask,
        initialData: []
    });

    // console.log("parentTask" , parentTask)
    // console.log("subTask",subTask)

    const { data: scheduleData = [] , isLoading: isScheduleLoading } = useQuery({
        queryKey: ['scheduleData'],
        queryFn: fetchProjectMilestones,
        initialData: [],
        onSuccess: (data) => {
            const result = data.map((item) => {
                return extrackScheduleInfo(item)
            })

            //  console.log("schedule" ,...result)
            setListData(...result)
        }
    });

    const { data: assignees = [] } = useQuery({
        queryKey: ['collaborators_data'],
        queryFn: fetchCollaborators,
        initialData: [],
        onSuccess: (data) => {
            const result = data.map((item) => {
                return {label: item.memberEmail , value: item.memberEmail}
            })
            setCollaborators(result)
        }
    });




    const labelSettings = {
        leftLabel: 'taskName'
    }

    const splitterSettings = {
        position: "30%"
    }

    const toolBar = ['Edit' , 'ExpandAll' , 'CollapseAll', 'Search']

    const taskFields = {
        id: '_id',
        name: 'taskName',
        startDate: 'startDate',
        endDate: 'endDate',
        duration: 'duration',
        status: 'status',
        //  progress: 'Progress',
        dependency: 'dependency',
        resourceInfo: "assignee",
        assignee: "assignee",
        child: 'subtasks',
    };
    const searchSettings = { operator: 'contains', key: '', ignoreCase: true }

    const resourceFields = {
        id: 'resourceId',
        name: 'resourceName'
    }

    const assigneeColumnTemplate = (props) => {
        return (
            <div className="avatar-group">
                <div className="avatar-group-item avatar-sm d-flex justify-content-center  align-items-center">
                    <span className="text-white fw-semibold" style={{'fontSize': '0.7rem'}}> A </span>
                </div>
            </div>
        )

    }
    useEffect(()=>{
        getTasks()
        console.log(scheduleData, "scheduled data")
    }, [])
    const statustemplate = (props) => {

        if(props.taskData.status){
            return (
                <span className={
                    props.taskData.status === "On Track" || props.taskData.status === "In Progress" ? "badge badge-on-track badge-on-track-text" :
                        props.taskData.status === "Not Started" ? "badge badge-delayed badge-delayed-text" :
                            props.taskData.status === "Completed" ? "badge badge-completed badge-completed-text" : props.taskData.status === "delayed" ? "badge badge-actual-delayed badge-actual-delayed-text" : "badge"

                } style={{fontSize: '0.845rem'}}> {props.taskData.status} </span>
            )
        }

    }
    const rowSelected = (props) => {
        //  console.log("row selected" , props)
        if(props.data?.duration === 0 ){
            //  console.log("Milestone" , props.data)
            setEditMilestone({...editMilestone , isEdit: true , data: {...props.data.taskData}})
            setOpenMilestone(true)
        }

        if(props.data?.level === 0 && props.data?.duration > 0) {
            //parent
            console.log(props.data)
            setEditTask({...editTask,isEdit: true , data: {
                    ...props.data.taskData,
                    assignee: _.join(props.data.taskData.assignee,"")
                }})
            handleTask(1)
        }

        if(props.data?.level === 1 && props.data?.duration > 0 ) {
            //sub task
            // console.log(props.data)
            setEditTask({...editTask,isEdit: true , data: {
                    ...props.data.taskData,
                    assignee: _.join(props.data.taskData.assignee,"")

                }})
            handleTask(2)
        }

        if(props.data?.level === 2 && props.data?.duration > 0) {
            //task
            setTaskId(props.data._id)
            setEditTask({...editTask,isEdit: true , data: {
                    ...props.data.taskData,
                    assignee: _.join(props.data.taskData.assignee,"")

                }})
            handleTask(3)
        }
    }

    const assignee_template = assigneeColumnTemplate.bind(this);
    const statusTemplate = statustemplate.bind(this);
       return (
           <>
               <div className="page-content">
                   <DashboardNavigation projectTitle={"Schedule"} />
                   <Row>
                       <Col sm="12">
                           <Card>
                               <CardHeader className={"py-2 "}>
                                   <Row className={"d-flex align-items-center"}>
                                       <Col xl={2} md={3} xs={12}>
                                           <h4 className="card-title">Project Schedule</h4>
                                       </Col>
                                       <Col xl={4} md={5} xs={12}>
                                           <div className={"container d-flex justify-content-start schedule-nav"}>
                                               <div className={viewTracker===1 ? "active": ""} onClick={()=>setViewTracker(1)}>
                                                   <FeatherIcon
                                                       icon="list"
                                                   />
                                                   <span> List View</span>
                                               </div>
                                               <div className={viewTracker===0 ? "active ms-3": ""} onClick={()=>setViewTracker(0)}>
                                                   <FeatherIcon
                                                       icon="sliders"
                                                   />
                                                   <span> Gantt View</span>
                                               </div>
                                           </div>
                                       </Col>
                                       {/* <Col xl={4} md={2} xs={12} style={{ textAlign: "right" }}>
                                        <Button
                                            type="button"
                                            color="primary"
                                            onClick={() => {
                                                tog_tasksImportModal();
                                            }}
                                            >
                                            Import Schedule
                                        </Button>
                                       </Col> */}
                                       <Col xl={6} md={4} xs={12} style={{ textAlign: "right" }}>
                                            <Button
                                                type="button"
                                                color="success"
                                                onClick={() => {
                                                    tog_tasksImportModal();
                                                }}
                                                style={{ marginRight: '1rem' }}
                                                >
                                                Import Schedule
                                            </Button>

                                           <Dropdown
                                               isOpen={menu}
                                               toggle={() => setMenu(!menu)}
                                               className="d-inline-block"
                                           >
                                               <DropdownToggle
                                                   className="btn btn-primary"
                                                   id="project-task"
                                                   tag="button"
                                               >
                                                <span className="d-xl-inline-block  ms-2 me-1">
                                                Add New
                                                </span>
                                                   <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                                               </DropdownToggle>
                                               <DropdownMenu className="dropdown-menu-center mt-1">
                                                   <div>
                                                       <a className="p-2" onClick={() => handleTask(1)}>
                                                           New Parent Task
                                                       </a>
                                                       <div className="dropdown-divider"> </div>
                                                   </div>

                                                   <div>
                                                       <a className="p-2" onClick={() => handleTask(2)}>
                                                           New Sub Task
                                                       </a>
                                                       <div className="dropdown-divider"> </div>
                                                   </div>
                                                   <div>
                                                       <a className="p-2" onClick={() => handleTask(3)}>
                                                           New Task
                                                       </a>
                                                       <div className="dropdown-divider"> </div>
                                                   </div>
                                                   <div>
                                                       <a className="p-2" onClick={() => setOpenMilestone(true)}>
                                                           New Milestone
                                                       </a>
                                                       <div className="dropdown-divider"> </div>
                                                   </div>
                                               </DropdownMenu>
                                           </Dropdown>
                                       </Col>
                                   </Row>
                               </CardHeader>
                               <CardBody>
                                   {
                                       viewTracker === 0 ? <>
                                           <GanttComponent
                                               id="Default"
                                               dataSource={scheduleData}
                                               treeColumnIndex={1}
                                               taskFields={taskFields}
                                               labelSettings={labelSettings}
                                               height="100%"
                                               resourceFields={resourceFields}
                                               resources={assigneeImages}
                                               dateFormat={"dd MMM, yyyy"}
                                               splitterSettings={splitterSettings}
                                               toolbar={toolBar}
                                               rowSelected={rowSelected.bind(this)}
                                               searchSettings = {searchSettings}
                                           >
                                               <ColumnsDirective>
                                                   {/* <ColumnDirective field="taskUniqueIdentifier" width="50" visible={false}></ColumnDirective> */}
                                                   <ColumnDirective field='_id' width='80' visible={false}></ColumnDirective>
                                                   <ColumnDirective
                                                       field="taskName"
                                                       headerText="Task"
                                                       width="250"
                                                       clipMode="EllipsisWithTooltip"
                                                   ></ColumnDirective>
                                                   <ColumnDirective field="startDate" width="140" ></ColumnDirective>
                                                   <ColumnDirective field="duration" width="90"></ColumnDirective>
                                                   <ColumnDirective field="status" headerText="Status"  template={statusTemplate}></ColumnDirective>
                                                   <ColumnDirective field="assignee" headerText="Assignee" template={assignee_template}></ColumnDirective>

                                                   <ColumnDirective field="dependency" visible={false}></ColumnDirective>
                                                   <ColumnDirective field="percentageProgress" headerText="Percentage Progress (%)"></ColumnDirective>
                                               </ColumnsDirective>
                                               <Inject services={[
                                                   Selection ,
                                                   // Edit ,
                                                   Toolbar
                                               ]} />
                                           </GanttComponent>
                                       </> : <>
                                           <div className={"container-fluid"}>
                                               <h6 className="primary-text-color fw-bold">
                                                   Tasks Progress
                                               </h6>
                                               <table className={"table task-progress mt-3 border"}>
                                                   <thead>
                                                   <tr>
                                                       <th>Task</th>
                                                       <th>Start Date</th>
                                                       <th>Duration</th>
                                                       <th>Assigned to</th>
                                                       <th>Status</th>
                                                       <th>Progress (%)</th>
                                                   </tr>
                                                   </thead>
                                                   <tbody>
                                                   {tasksList.length > 0 ? (
                                                       tasksList.map((task) => (
                                                           <React.Fragment key={task.taskUniqueIdentifier}>
                                                               <tr onClick={() => toggleTask(task.taskUniqueIdentifier, "editTask", task)}>
                                                                   <td> {openTask === task.taskUniqueIdentifier ? <>
                                                                       <FeatherIcon
                                                                           icon="chevron-down"
                                                                           className={"table-icon"}
                                                                       />
                                                                   </> : <>
                                                                       <FeatherIcon
                                                                           icon="chevron-right"
                                                                           className={"table-icon"}
                                                                       />
                                                                   </>}{task.taskName}</td>
                                                                   <td>{new Date(task.startDate).toLocaleDateString()}</td>
                                                                   <td>{Math.floor((new Date(task.endDate) - new Date(task.startDate)) / (1000 * 60 * 60 * 24))} Days</td>
                                                                   <td><div className={"assignee-badge"}>{task.assignee.charAt(0)}</div></td>
                                                                   <td><span className={task.status==="Not Started" ? "badge status-badge": task.status==="Completed" ? "badge complete-status-badge" :  "badge progress-status-badge"}>{task.status}</span></td>
                                                                   <td>{task.percentageProgress} %</td>
                                                               </tr>
                                                               {task.subtasks && task.subtasks.length > 0 && (
                                                                   task.subtasks.map((subtask, index) => (
                                                                       <>
                                                                           <tr onClick={()=>toggleSubTask(subtask.taskUniqueIdentifier, "editsubTask", subtask)}  key={`${task.taskUniqueIdentifier}-${index}`} className={"subtask"}>
                                                                               <td> {subtask.subtasks.length > 0 && <>
                                                                                   {openSubTask === subtask.taskUniqueIdentifier ? <>
                                                                                       <FeatherIcon
                                                                                           icon="chevron-down"
                                                                                           className={"table-icon"}
                                                                                       />
                                                                                   </> : <>
                                                                                       <FeatherIcon
                                                                                           icon="chevron-right"
                                                                                           className={"table-icon"}
                                                                                       />
                                                                                   </>}
                                                                               </>}{subtask.taskName}</td>
                                                                               <td>{new Date(subtask.startDate).toLocaleDateString()}</td>
                                                                               <td>{Math.floor((new Date(subtask.endDate) - new Date(subtask.startDate)) / (1000 * 60 * 60 * 24))} Days</td>
                                                                               <td><div className={"assignee-badge"}>{subtask.assignee.charAt(0)}</div></td>
                                                                               <td><span className={subtask.status==="Not Started" ? "badge status-badge": subtask.status==="Completed" ? "badge complete-status-badge" :  "badge progress-status-badge"}>{subtask.status}</span></td>
                                                                               <td>{subtask.percentageProgress} %</td>
                                                                           </tr>
                                                                           {
                                                                               subtask.subtasks && subtask.subtasks.length > 0 && (
                                                                                   subtask.subtasks.map((subtask1, subtask1index)=>(
                                                                                       <>
                                                                                           <tr onClick={()=>toggleSubTask1("editsubTask", subtask1)} key={`${subtask.taskUniqueIdentifier}-${subtask1index}`} className={"subtask1"}>
                                                                                               <td>{subtask1 ? subtask1.taskName : 'Pending'}</td>
                                                                                               <td>{subtask1 ? new Date(subtask1.startDate).toLocaleDateString() : new Date().toLocaleDateString()}</td>
                                                                                               <td>{subtask1 ? Math.floor((new Date(subtask1.endDate) - new Date(subtask1.startDate)) / (1000 * 60 * 60 * 24)) : '0'} Days</td>
                                                                                               <td><div className={"assignee-badge"}>
                                                                                                   {subtask1 ? subtask1.assignee.charAt(0) : 'A'}
                                                                                               </div> </td>
                                                                                               <td><span className={subtask1 ? (subtask1.status === "Not Started" ? "badge status-badge" : subtask1.status === "Completed" ? "badge complete-status-badge" : subtask1.status === "delayed" ? "badge delayed-status-badge" : "badge progress-status-badge") : ("badge progress-status-badge")}>{subtask1 ? subtask1.status : 'Not started'}</span></td>
                                                                                               <td>{subtask1 ? subtask1.percentageProgress : "0"} %</td>
                                                                                           </tr>
                                                                                       </>
                                                                                   ))
                                                                               )
                                                                           }
                                                                       </>
                                                                   ))
                                                               )}

                                                           </React.Fragment>
                                                       ))
                                                   ) : (
                                                       <tr>
                                                           <td colSpan={5}>No tasks completed</td>
                                                       </tr>
                                                   )}
                                                   </tbody>

                                               </table>
                                           </div>
                                       </>
                                   }

                               </CardBody>
                           </Card>
                       </Col>
                       <MilestoneModal
                           onCloseClick={() => handleClose(0)}
                           isopenMilestone={isMilestoneOpen}
                           projectId={params.id}
                           editData={editMilestone}
                           data={{dependency: dependencyList}}
                       />
                       <TaskModal
                           onCloseClick={() => handleClose(taskType)}
                           isopenTask={isTaskOpen}
                           projectId={params.id}
                           data={{parent: parentTask , collaborators: collaborators , subtask: subTask , dependency: taskdependencyList}}
                           taskType={taskType}
                           editData={editTask}
                           taskId={taskId}
                           getTasks={()=>getTasks()}
                       />

                        <ImportTasksModal
                           onCloseClick={() => tog_tasksImportModal()}
                           isOpen={importTasksModal}
                           projectId={params.id}
                           
                       />
                   </Row>
               </div>
           </>

    );
};

export default NewSchedule;
