import React from 'react'
import {useField} from '@formiz/core'

export const TextAreaField = (props) => {
   const {setValue,value , errorMessage , isValid , isSubmitted , id} = useField(props)
   const {  required , placeholder , name } = props
   const [isTouched, setIsTouched] = React.useState(false)
   const showError = !isValid && (isTouched || isSubmitted)
   

   return (
       
    <div className="row">
      <div className="col-md-12">
        <div className="mb-3 mb-3">
            <textarea
               placeholder={placeholder || ''} 
               id={id} 
            //    type={type || 'text' }  
               className={`form-control ${(showError) ? 'is-invalid' : ''}`} 
               value={value ?? ''}
               onChange={e => setValue(e.target.value)}
               onBlur={() => setIsTouched(true)}
               aria-invalid={showError}
               aria-required={!!required}
               aria-describedby={showError ? `${id}-error` : null}
            > 

            </textarea>
            
              {showError && (
               <div id={`${id}-error`} className="invalid-feedback d-block">
                  { errorMessage }
               </div>
               )}
        </div>
      </div>
    </div>
                
        
   )
}