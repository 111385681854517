import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Form } from 'reactstrap';

import * as url from "../../helpers/jengasmart_api_urls";

import axios from 'axios';

//Verification code package
import AuthCode from "react-auth-code-input";

//import images
import logo from "../../assets/images/logo.svg";
import CarouselPage from './CarouselPage';

const TwoStepVerfication = (props) => {
    const [code, setCode] = useState('');

    //meta title
    document.title = "Verify Email | Jenga App";

    function checkCode(e) {
      e.preventDefault();
  
      var config = {
        headers: {
          'Content-Type': 'application/json',
        //   'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
      };
      
      var data = {
        email: JSON.parse(localStorage.getItem('authUser')).user.email,
        code: code
      };
      
      var api_url = process.env.REACT_APP_API_BASE + url.POST_LOGIN_DETAILS + '/confirmcode';
  
      axios.post(api_url, data, config)
      .then(res => {
        if(res.data.success) {
            props.history.push('/confirmation-success');
        }
      })
      .catch(error => {
        if (error.response) {
            if(error.response.status === 401 || error.response.status === 403) {
                props.history.push('/login');
            }
            else {
                setError(error.response.data.error[0]);
            }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
    }


    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4} md={5} className="col-xxl-4">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={logo} alt="" height="28" /> <span className="logo-txt"></span>
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">

                                                <div className="avatar-lg mx-auto">
                                                    <div className="avatar-title rounded-circle bg-light">
                                                        <i className="bx bxs-envelope h2 mb-0 text-primary"></i>
                                                    </div>
                                                </div>
                                                <div className="p-2 mt-4">

                                                    <h4>Verify your email</h4>
                                                    <p className="mb-5">Please enter the 6 digit code sent to your email at <span className="fw-bold">{JSON.parse(localStorage.getItem('authUser')).user.email}</span></p>

                                                    <Form onSubmit={e => checkCode(e)}>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mb-3 verification">
                                                                    <label htmlFor="digit1-input" className="visually-hidden">Dight 1</label>
                                                                    <AuthCode
                                                                        characters={6}
                                                                        className="form-control form-control-lg text-center"
                                                                        allowedCharacters="^[0-9]"
                                                                        inputStyle={{
                                                                            width: "50px",
                                                                            height: "42px",
                                                                            padding: "8px",
                                                                            borderRadius: "8px",
                                                                            fontSize: "16px",
                                                                            textAlign: "center",
                                                                            marginRight: "15px",
                                                                            border: "1px solid #ced4da",
                                                                            textTransform: "uppercase",
                                                                        }}
                                                                        onChange={(res) => setCode(res)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4">
                                                            <button type="submit" className="btn btn-primary w-100">Confirm</button>
                                                        </div>
                                                    </Form>
                                                    
                                                </div>

                                            </div>

                                            <div className="mt-5 text-center">
                                                <p className="text-muted mb-0">Didn't receive an email ? <Link to="#"
                                                    className="text-primary fw-semibold"> Resend </Link> </p>
                                            </div>
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">© {new Date().getFullYear()} Jenga App</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TwoStepVerfication;