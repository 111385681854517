const languages = {
  sp: {
    label: "Spanish",
  },
  gr: {
    label: "German",
  },
  it: {
    label: "Italian",
  },
  rs: {
    label: "Russian",
  },
  en: {
    label: "English",
  },
};

export default languages;
