import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { 
  Card, 
  CardBody, 
  Col, 
  Container, 
  Row, 
  Button,
  Table
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import * as url from "../../helpers/jengasmart_api_urls";

//redux
import { useSelector, useDispatch } from "react-redux";

import axios from 'axios';
import { map } from "lodash";
import moment from 'moment';
import ReactPaginate from "react-paginate";

const TrackLogs = (props) => {
    //meta title
    document.title = "Track Progress | Jenga App";

    const dispatch = useDispatch();
    const history = useHistory();
    const [page, setPage]=useState(1);
    const [totalPages, setTotalPages] = useState(10);
    const [list, setList] = useState([]);
    const [listError, setListError] = useState(false);
    const [error, setError] = useState(false);
    const handlePageClick = (event) => {
        setPage(event.selected);
        console.log(event.selected, "Selected")
    };
    const getLogs = () => {
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        if (!authUser || !authUser.token || !authUser.user.id) {
            props.history.push('/login');
            return;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authUser.token
            }
        };

        const api_url = `${process.env.REACT_APP_API_BASE + url.POST_LOG_DETAILS}/project/${props.projectId}?page=${page}`;

        axios.get(api_url, config)
            .then(res => {
                setList(res.data.data);
                setTotalPages(res.data.totalPages);
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        props.history.push('/login');
                    } else {
                        setError(error.response.data.error ? error.response.data.error[0] : 'An error occurred');
                    }
                } else if (error.request) {
                    console.log('Request error:', error.request);
                } else {
                    console.log('Error:', error.message);
                }
            });
    };
    useEffect(() => {
     getLogs()
    }, [page]);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Daily Progress Log" />
                    <p className="card-title-desc mb-3">
                      Viewing progress for all logs
                    </p> */}

                    <Row>
                      <Col xl={12} md={12} xs={12}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem="Daily Progress Log" />
                      </Col>
                      <Col xl={6} md={6} xs={12}>
                        <p className="card-title-desc mb-3">
                          Viewing progress for all logs you have added
                        </p>
                      </Col>
                      <Col xl={6} md={6} xs={12} style={{ textAlign: 'right' }}>
                        <Button 
                          className="mb-3"
                          type="button" 
                          color="primary"
                          onClick={() => {
                            props.history.push('/add-progress');
                          }}>
                          Add New
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Card>
                          <CardBody>
                            <div className="table-responsive">
                              <Table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Project</th>
                                    <th>User</th>
                                    <th>Log Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    listError || list.length === 0 ? (
                                      <tr>
                                        <td colSpan={6} className="text-center">No logs added</td>
                                      </tr>
                                    ) : (
                                      <>
                                        {map(list, (item, key) => (
                                          <tr key={key} onClick={() => history.push("/view-progress/" + item._id)}>
                                            <th scope="row">{key + 1}</th>
                                            <td>{item.projectId?.projectName}</td>
                                            <td>{item.userId?.firstName + " " + item.userId?.lastName}</td>
                                            <td>{moment(item.logDate).format("MMMM Do YYYY")}</td>
                                          </tr>
                                        ))}
                                      </>
                                    )
                                  }
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <ReactPaginate pageCount={totalPages}
                                   previousLabel={'previous'}
                                   nextLabel={'next'}
                                   breakLabel={'...'}
                                   breakClassName={'break-me'}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={5}
                                   onPageChange={handlePageClick}
                                   containerClassName={'pagination'}
                                   subContainerClassName={'pages pagination'}
                                   activeClassName={'active'}
                    />

                </Container>
            </div>
        </React.Fragment>
    );
}

export default TrackLogs;