import PropTypes from 'prop-types'
import React , {useState,useEffect} from "react"
import { 
    Modal, 
    ModalBody ,  
    Alert
} from "reactstrap"
import { Formiz, useForm } from '@formiz/core'
import { InputField } from "../../components/forms/InputField.js";
import { SelectField } from '../forms/SelectField.js';
import { LoadingIndicator } from "../../components/Common";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useCompanyInventory } from '../../hooks/inventoryHook.js';
import { isEmpty } from 'lodash';

export function AssignItemModal({isopenAssignItem,onCloseClick,assignItem , handleItem , projectSites , uniqueCollaborators}){
    const assignItemForm = useForm()
    const {isLoading,assignInventory,isAssignLoading} = useCompanyInventory(onCloseClick)

    // console.log("assignItem Modal",assignItem)

    const handleSubmit = async (values) => {  
      //  handleItem(values)
      const result = {
        ...values,
        _id: assignItem._id,
        status: assignItem.status,
        serialNumber: assignItem.serialNumber,
        modelNumber: assignItem.modelNumber,
        manufacturer: assignItem.manufacturer,
        description: assignItem.description

      }

      
      if(!isEmpty(result?.projectId)) {
        result["status"] = "Assigned"
      }
      // console.log("result",result)
      try {
        await assignInventory(result)
      } catch (error){
        console.log("error" , error)
      }
    }

   
    return (
      <Modal
        isOpen={isopenAssignItem}
        toggle={onCloseClick}
        centered={true}
        size={"lg"}
      >
        <Formiz 
           connect={assignItemForm}
           onValidSubmit={handleSubmit}>
          <form
            className="w-100 needs-validation"
            noValidate
            onSubmit={assignItemForm.submit}
          >
            {/* <h3>Add Inventory</h3> */}
            <div className="d-flex flex-column w-100 p-4">
              <h3 className="mb-1" style={{color: "#194973"}}>Assign Item</h3>
              <div className="d-flex flex-row w-100 my-3">
                <div className="col-6">
                  <h4 className="mb-2 pb-2" style={{ color: "#194973" }}>
                    {assignItem?.inventoryName}
                  </h4>
                  <h6 className="mb-1 pb-2">
                    Manufacturer: {assignItem?.manufacturer}
                  </h6>
                  <h6 className="mb-1 pb-2">
                    Serial Number: {assignItem?.serialNumber}
                  </h6>
                  <h6 className="mb-1 pb-2">
                    Model Number: {assignItem?.modelNumber}
                  </h6>
                </div>
                <div className="col-6">
                  <h4 className="mb-2" style={{ color: "#194973" }}>
                    Description
                  </h4>
                  <h6>{assignItem?.description}</h6>
                </div>
              </div>
              <div className="d-flex flex-row w-100">
                <div className="col-6 p-2">
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Borrow date</h6>
                    <InputField
                      name={"borrowDate"}
                      required={"Please select a date"}
                      type="date"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Return date</h6>
                    <InputField
                      name={"returnDate"}
                      required={"Please select a date"}
                      type="date"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Quantity</h6>
                    <InputField
                      name={"assignedQuantity"}
                      required={"Please enter a quantity"}
                      type="number"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                </div>
                <div className="col-6 p-2">
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Project site</h6>
                    <SelectField
                      name={"projectId"}
                      required={"please select a project site"}
                      data={projectSites}
                      defaultOption="Select a project site"
                    />
                  </div>
                  <div className="w-100  mb-3">
                    <h6 className="mb-1">Assignee</h6>
                    <SelectField
                      name={"assignee"}
                      required={"please select an assignee"}
                      data={uniqueCollaborators}
                      defaultOption="Select an assignee"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-end gap-3">
                <button className="btn btn-primary mb-3" type="submit">
                  {isLoading ? <LoadingIndicator /> : "Save"}
                </button>
                <a
                  className="align-self-center"
                  onClick={onCloseClick}
                  style={{
                    textDecoration: "underline !important",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </a>
              </div>
            </div>
          </form>
        </Formiz>
        <ToastContainer />
      </Modal>
    );
}

AssignItemModal.propTypes = {
    onCloseClick: PropTypes.func,
    isopenAssignItem: PropTypes.bool,
    assignItem: PropTypes.object,
    projectSites: PropTypes.array,
    uniqueCollaborators: PropTypes.array 
    // projectId: PropTypes.string,
    // taskType: PropTypes.number,
    // data: PropTypes.object

}