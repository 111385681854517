import React, { useEffect, useState } from 'react';
import {  useParams , useHistory  } from 'react-router-dom';


import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as url from "../../helpers/jengasmart_api_urls";

import moment from 'moment';

import axios from 'axios';
import queryString from 'query-string';
import { useQuery , useMutation } from "@tanstack/react-query";
import { Formiz, FormizStep, useForm } from "@formiz/core";
import { InputField } from "../../components/forms/InputField.js";
import { TextAreaField } from "../../components/forms/TextAreaField.js";
import { SelectField } from "../../components/forms/SelectField.js";
import { LoadingIndicator } from "../../components/Common";

import {
    Alert,
    Container,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    FormGroup,
    Label,
    Input,
    Button,
    FormFeedback,
    Form,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
  } from "reactstrap";

const EditSchedule = (props) => {

    const [activeTab, setactiveTab] = useState(1);

    const [subTask, setsubTasks] = useState([]);
    const [milestone, setMilestone] = useState({});
    const [task, setTask] = useState({});
    const [collaboratorsList, setCollaboratorsList] = useState([]);


    const taskForm = useForm();
    const history = useHistory();

     
    let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("authUser")).token,
        },
      };

      const {milestoneId} = useParams();
    
      const searchQuery = new URLSearchParams(document.location.search)
      
      const projectName= searchQuery.get('project') 
      const projectId = searchQuery.get('projectId')
  
      const MILESTONE_URL = process.env.REACT_APP_API_BASE + "milestones/" + milestoneId;  
      
      let task_endpoint = process.env.REACT_APP_API_BASE + url.POST_TASKS_DETAILS;
  let milestone_endpoint = process.env.REACT_APP_API_BASE + "milestones";
  let subtask_endpoint = process.env.REACT_APP_API_BASE + "subtasks";

  const addMilestone = async (data) => {
    return await axios.post(milestone_endpoint, data, config);
  };

  const addTask = async (data) => {
    return await axios.post(task_endpoint, data, config);
  };

  const handleSubTask = async (data) => {
    return await axios.post(subtask_endpoint, data, config);
  };

  const { mutate: milestoneMutation, isLoading: isMilestoneLoading } =
    useMutation({
      mutationFn: addMilestone,
      onSuccess: (data) => {
        // console.log("milestone response" , data?.data.data)
        const response = data?.data.data;
        const taskData = { milestoneId: response._id, ...taskForm.values.task };

        if (
          taskData.hasOwnProperty("milestoneId") &&
          taskData["milestoneId"] !== undefined
        ) {
          // console.log("inside taskMutation: taskData",taskData)
          taskMutation(taskData);
        }
      },
      onError: (error) => {
        console.log("milestone error", error);
      },
    });

  const taskIdChecker = (result) => {
    for (let i = 0; i < result.length; i++) {
      const subtask = result[i];
      if (
        !subtask.hasOwnProperty("taskId") ||
        subtask["taskId"] === undefined
      ) {
        return false; // If any object does not have taskId, return false immediately
      }
    }
    return true;
  };

  const { mutate: taskMutation, isLoading: istaskLoading } = useMutation({
    mutationFn: addTask,
    onSuccess: (data) => {
      console.log("task response", data?.data.data);
      const response = data?.data.data;
      const result = subTask.map((item, index) => {
        return { ...item, taskId: response.task._id };
      });
      const isTaskId = taskIdChecker(result);
      console.log("tasId", isTaskId);
      console.log("result", result);
      if (isTaskId) {
        console.log("inside tasId");
        subtaskMutation(result);
      }
    },
    onError: (error) => {
      console.log("task error", error);
    },
  });

  const { mutate: subtaskMutation, isLoading: isSubtaskLoading } = useMutation({
    mutationFn: handleSubTask,
    onSuccess: (data) => {
      console.log("subtask response", data);
    },
    onError: (error) => {
      console.log("subtask error", error);
    },
  });

  const prevTab = () => {
    setactiveTab((prevState) => prevState - 1);
    taskForm.prevStep();
  };

  const nextTab = () => {
    setactiveTab((prevState) => prevState + 1);
  };

  const validateObject = (obj) => {
    //  console.log("obj" , obj)
    for (const key in obj) {
      if (
        (obj.hasOwnProperty(key) && typeof obj[key] !== "string") ||
        obj[key].trim() === ""
      ) {
        return false;
      }
    }
    return true;
  };

  const addSubtask = (subtask) => {
    if (validateObject(subtask)) {
      setsubTasks((prevState) => [...prevState, subtask]);
    }

    // taskForm.reset({ only: [ 'currentStep' , 'values' , 'isPristine '] })
  };

  const removeSubTask = (taskIndex) => {
    const updatedTask = subTask.filter((task, index) => index !== taskIndex);
    setsubTasks(updatedTask);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(taskForm);
    // if (!taskForm.currentStep || !taskForm.currentStep.isValid) {
    //   // Trigger only the Formiz submitStep
    //   taskForm.submitStep()
    //   return
    // }

    if (taskForm.currentStep.name === "milestones") {
      console.log("milestones", taskForm.values.milestone);
      taskForm.currentStep.isValid ? taskForm.submitStep() : null;
      //  milestoneMutation(taskForm.values.milestone)
      taskForm.currentStep.isValid ? nextTab() : null;
    }

    if (taskForm.currentStep.name === "tasks") {
      console.log("task", taskForm.values.task);
      taskForm.currentStep.isValid ? taskForm.submitStep() : null;
      //taskForm.currentStep.isValid ? nextTab() : null;
      history.push(`/projects/${projectId}?selectedtab=2`);
      // !taskForm.currentStep || !taskForm.currentStep.isValid ? taskForm.submitStep() : null
    }

    if (taskForm.currentStep.name === "subtask") {
      console.log("taskForm.currentStep.name", subTask);
      //milestoneMutation(taskForm.values.milestone);
    }

    //Trigger the Formiz submitStep
    // if(taskForm.currentStep.isValid){
    //   taskForm.submitStep()
    //   nextTab()
    // }
    //taskForm.submitStep()
  };

  const addingSubTask = (e) => {
    e.preventDefault();
    taskForm.submitStep();
    addSubtask(taskForm.values.subtask);
  };





      async function fetchProjectMilestone(){
        const {data} = await axios.get(MILESTONE_URL,config)


        return data;
    }

    const { data: milestoneData  } = useQuery({
      queryKey: ["milestone"],
      queryFn: fetchProjectMilestone,
      initialData: {},
    });

    useEffect(() => {
        var config = {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("authUser")).token,
          },
        };
    
        var api_url =
          process.env.REACT_APP_API_BASE +
          url.POST_PROJECT_DETAILS +
          "/collaborators/" +
          projectId
        //   params.id;
    
        axios
          .get(api_url, config)
          .then((res) => {
            setCollaboratorsList(res.data.data);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401 || error.response.status === 403) {
                props.history.push("/login");
              } else {
                setProjectError(error.response.data.error[0]);
              }
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      }, []);

    //   console.log(taskForm)
    //   console.log("milestone data" , milestoneData)

     return (
        <React.Fragment>
      <div className="page-content">
        <Container fluid id="basic-pills-wizard" className="twitter-bs-wizard">
          {/* Render Breadcrumbs */}
          <i
            className="fas fa-arrow-circle-left"
            style={{
              fontSize: 20,
              color: "blue",
              cursor: "pointer",
              marginBottom: 10,
            }}
            onClick={() => props.history.goBack()}
          ></i>{" "}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Edit Task" />
          <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
            <NavItem>
              <NavLink
                href="#"
                className={
                  taskForm?.currentStep?.index + 1 === 1 ? "active" : ""
                }
              >
                <div
                  className="step-icon mb-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Seller Details"
                >
                  <i className="bx bx-list-ul"></i>
                </div>
                <p>Milestone</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={
                  taskForm?.currentStep?.index + 1 === 2 ? "active" : ""
                }
              >
                <div
                  className="step-icon mb-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Company Document"
                >
                  <i className="bx bx-task"></i>
                </div>
                <p>Tasks</p>
              </NavLink>
            </NavItem>

            {/* <NavItem>
              <NavLink
                href="#"
                className={
                  taskForm?.currentStep?.index + 1 === 3 ? "active" : ""
                }
              >
                <div
                  className="step-icon mb-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Bank Details"
                >
                  <i className="bx bx-list-ol"></i>
                </div>
                <p>Sub Tasks</p>
              </NavLink>
            </NavItem> */}
          </ul>
          <Formiz
            connect={taskForm}
            initialValues={{
              milestone: {
                projectId: projectId,
                userId: JSON.parse(localStorage.getItem("authUser")).user.id,
                startDate: milestoneData.hasOwnProperty("success") ? (new Date(milestoneData?.data.startDate)).toISOString().split('T')[0] : null,
                endDate: milestoneData.hasOwnProperty("success") ? (new Date(milestoneData?.data.endDate)).toISOString().split('T')[0] : null,
                milestoneName: milestoneData.hasOwnProperty("success") ? milestoneData?.data.taskName : null,
                description: milestoneData.hasOwnProperty("success") ? milestoneData?.data.description : null,
                assignee: JSON.parse(localStorage.getItem("authUser")).user.email 
              },
              task: {
                projectId: projectId,
                userId: JSON.parse(localStorage.getItem("authUser")).user.id,
                startDate: milestoneData.hasOwnProperty("success") ? (new Date(milestoneData?.data.tasks[0].startDate)).toISOString().split('T')[0] : null,
                endDate: milestoneData.hasOwnProperty("success") ? (new Date(milestoneData?.data.tasks[0].endDate)).toISOString().split('T')[0] : null,
                taskName: milestoneData.hasOwnProperty("success") ? milestoneData?.data.tasks[0].taskName : null,
                description: milestoneData.hasOwnProperty("success") ? milestoneData?.data.tasks[0].description : null,
                assignee: JSON.parse(localStorage.getItem("authUser")).user.email
              },
              subtask: {
                projectId: projectId,
                userId: JSON.parse(localStorage.getItem("authUser")).user.id,
              },
            }}
          >
            <form noValidate onSubmit={handleSubmit}>
              <FormizStep
                name="milestones"
                className="row"
                // order={2}
              >
                <div className="col-xl-6 mx-auto">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        Edit milestone for {projectName}
                      </h4>
                      <p className="card-title-desc">
                        Provide all the required details about the milestone.
                      </p>
                    </div>
                    <div className="card-body">
                      <InputField
                        name="milestone.projectId"
                        label=""
                        required=""
                        type="hidden"
                      />

                      <InputField
                        name="milestone.startDate"
                        label="Milestone start date"
                        required="A milestone start date is required"
                        type="date"
                      />
                      <InputField
                        name="milestone.endDate"
                        label="Milestone end date"
                        required="A milestone end date is required"
                        type="date"
                      />
                      <InputField
                        name="milestone.milestoneName"
                        label="Milestone title"
                        placeholder="Milestone title"
                        required="A milestone title is required"
                        type="text"
                        
                      />
                      <SelectField
                        name="milestone.assignee"
                        label="Select assignee"
                        required="An assignee is required"
                        data={collaboratorsList}
                      />
                      <TextAreaField
                        name="milestone.description"
                        label="Description"
                        required="A description  required"
                      />
                      <InputField
                        name="milestone.userId"
                        label=""
                        required=""
                        type="hidden"
                        value={
                          JSON.parse(localStorage.getItem("authUser")).user.id
                        }
                      />
                    </div>
                  </div>
                </div>
              </FormizStep>
              <FormizStep
                name="tasks"
                className="row"
                // order={3}
              >
                <div className="col-xl-6 mx-auto">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        Edit task for {projectName}
                      </h4>
                      <p className="card-title-desc">
                        Edit task for the milestone.
                      </p>
                    </div>
                    <div className="card-body">
                      <InputField
                        name="task.projectId"
                        label=""
                        required=""
                        type="hidden"
                      />
                      <InputField
                        name="task.startDate"
                        label="Task start date"
                        required="A task start date is required"
                        type="date"
                      />
                      <InputField
                        name="task.endDate"
                        label="Task end date"
                        required="A task end date is required"
                        type="date"
                      />
                      <InputField
                        name="task.taskName"
                        label="Task title"
                        placeholder="Task title"
                        required="A task title is required"
                        type="text"
                      />
                      <SelectField
                        name="task.assignee"
                        label="Select assignee"
                        required="An assignee is required"
                        data={collaboratorsList}
                      />
                      <TextAreaField
                        name="task.description"
                        label="Description"
                        required="A description is required"
                      />
                      <InputField
                        name="task.userId"
                        label=""
                        required=""
                        type="hidden"
                      />
                    </div>
                  </div>
                </div>
              </FormizStep>
              {/* <FormizStep
                name="subtask"
                className="row"
              >
                <div className="col-xl-6 mx-auto">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        Edit a sub task for {projectName}
                      </h4>
                      <p className="card-title-desc">
                       Edit a sub task for the task created.
                      </p>
                    </div>
                    <div className="card-body">
                      <InputField
                        name="subtask.projectId"
                        label=""
                        required=""
                        type="hidden"
                      />
                      <InputField
                        name="subtask.startDate"
                        label="Start date"
                        required="A start date is required"
                        type="date"
                      />
                      <InputField
                        name="subtask.endDate"
                        label="End date"
                        required="An end date is required"
                        type="date"
                      />
                      <InputField
                        name="subtask.subtaskName"
                        label="Sub task title"
                        placeholder="Sub task title"
                        required="A  title is required"
                        type="text"
                      />
                      <SelectField
                        name="subtask.assignee"
                        label="Select assignee"
                        required="An assignee is required"
                        data={collaboratorsList}
                      />
                      <TextAreaField
                        name="subtask.description"
                        label="Description"
                        required="A description is required"
                      />
                      <InputField
                        name="subtask.userId"
                        label=""
                        required=""
                        type="hidden"
                      />

                      <a
                        className="btn btn-primary"
                        href="#"
                        role="button"
                        onClick={(e) => addingSubTask(e)}
                      >
                        Add sub task
                      </a>
                    </div>
                  </div>
                </div>
                <Col xl={subTask.length !== 0 ? 6 : "null"}>
                  {subTask.length !== 0
                    ? subTask.map((task, index) => {
                        return (
                          <Card
                            key={"sub--task--" + index}
                            className="text-center mb-2"
                          >
                            <CardBody>
                              <UncontrolledDropdown className="dropdown text-end">
                                <DropdownToggle
                                  className="text-muted font-size-16"
                                  tag="a"
                                >
                                  <i className="bx bx-dots-horizontal-rounded"></i>
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    to="#"
                                    onClick={() => removeSubTask(index)}
                                  >
                                    Remove
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>

                              <h1
                                className="font-size-20 mb-3"
                                style={{ textAlign: "left" }}
                              >
                                Subtask {Number(index) + 1}
                              </h1>

                              <h5
                                className="font-size-15 mb-1"
                                style={{ textAlign: "left" }}
                              >
                                {task.subtaskName}
                              </h5>
                              <p
                                className="text-muted mb-2"
                                style={{ textAlign: "left" }}
                              >
                                {task.description}
                              </p>
                            </CardBody>
                          </Card>
                        );
                      })
                    : null}
                </Col>
              </FormizStep> */}

              <div
                className={`d-flex flex-row col-xl-6  justify-content-between mx-auto`}
              >
                {!taskForm.isFirstStep && (
                  <Button color="dark" type="button" onClick={prevTab}>
                    Back
                  </Button>
                )}
                {taskForm.isLastStep ? (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!taskForm.isValid}
                  >
                    {isMilestoneLoading || istaskLoading || isSubtaskLoading ? (
                      <LoadingIndicator />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!taskForm.isStepValid}
                  >
                    Continue
                  </Button>
                )}
              </div>
            </form>
          </Formiz>
        </Container>
      </div>
    </React.Fragment>
     ) 
}

export default EditSchedule;