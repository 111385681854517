import React from "react";
import PropTypes from "prop-types";

export function CardPlaceholder() {
  return (
    <div className="">
      <h5 className="card-title placeholder-glow">
        <span className="placeholder col-6"></span>
      </h5>
      <p className="card-text placeholder-glow">
        <span className="placeholder col-7"></span>{" "}
        <span className="placeholder col-4"></span>{" "}
        <span className="placeholder col-4"></span>{" "}
        <span className="placeholder col-6"></span>{" "}
        <span className="placeholder col-8"></span>{" "}
      </p>
    </div>
  );
}

export function PlaceholderText() {
  return (
    <div>
      <span className="placeholder placeholder-glow col-6"></span>
      <span className="placeholder placeholder-glow w-75"></span>{" "}
      {/* <span className="placeholder" style="width: 25%;"></span> */}
    </div>
  );
}

export function LoadingIndicator(){
    return (
      <div role="status" className="ms-2 spinner-border spinner-border-sm text-light">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
}
export function SubmitIndicator(){
    return (
        <div role="status" className="ms-2 spinner-border spinner-border-sm text-light">
            <span className="visually-hidden">Submitting...</span>
        </div>
    );
}

export function FullPageLoadingIndicator(){
    return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>);
}