import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

const RadialChart = (props) => {
  const [percentageArr, setPercentageArr] = useState([0]);
  useEffect(() => {
    const seriesArr = [];
    const percentage = (Number(props.expenditure) / Number(props.expectedBudget)) * 100;
    seriesArr.push(percentage.toFixed(2));
    setPercentageArr(seriesArr);
  }, []);

  // const series = [25]
  const options = {
    chart: {
      // fontFamily: "Hauora",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "12px",
            offsetY: 15,
            color: "#194973",
            fontWeight: 500,
            // fontFamily: "Hauora"

          },
          value: {
            fontSize: "30px",
            fontWeight: 700,
            color: "#194973",
            offsetY: -20,
            // fontFamily: "Hauora",

          },
          total: {
            show: true,
            label: "Expenditure",
            fontSize: "12px",
            color: "#194973",
            fontWeight: 500,
            // fontFamily: "Hauora"

            // formatter: function (w) {
            //   // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
            //   return props.currency + " " + Number(props.expenditure)
            // },
          },
        },
      },
    },

    labels: ["Expenditure"],
    colors: ["#F2D8A7"],
  }

  return (
    <ReactApexChart
      options={options}
      series={percentageArr}
      type="radialBar"
      width="100%"
      height="250"
    />
  )
}

export default RadialChart