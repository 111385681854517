import React, { useState, useEffect } from "react"
import {Link, useParams} from 'react-router-dom';
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    Col,
    Form,
    Label,
    Input,
    Row,
    Modal,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Table
} from "reactstrap";
import * as url from "../../../helpers/jengasmart_api_urls";

import axios from 'axios';
import { map } from "lodash";

import {LoadingIndicator} from '../../../components/Common'
import DashboardNavigation from "./DashboardNavigation";


const Collaborators = (props) => {
    const [collaboratorsModal, setCollaboratorsModal] = useState(false);
    const [list, setList] = useState([]);
    const [error, setError] = useState(false);
    const [listError, setListError] = useState(false);
    const [memberEmail, setMemberEmail] = useState('');
    const [projectRole, setProjectRole] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [removeId, setRemoveId] = useState('');
    const [isSaving,setSaving] = useState(false)
    const {id} =useParams()

    function tog_collaboratorsModal() {
        setCollaboratorsModal(!collaboratorsModal);
    }

    function tog_confirmModal() {
        setConfirmModal(!confirmModal);
    }

    useEffect(() => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
            }
        };

        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/collaborators/' + id;

        axios.get(api_url, config)
            .then(res => {
                setList(res.data.data);
            })
            .catch(error => {
                if (error.response) {
                    if(error.response.status === 401 || error.response.status === 403) {
                        props.history.push('/login');
                    }
                    else {
                        setError(error.response.data.error[0]);
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    }, []);

    function addCollaborator(e) {
        e.preventDefault();

        var newMemberObj = {};
        var newMemberArr = [];

        if((JSON.parse(localStorage.getItem('authUser')).user.email) !== memberEmail) {
            newMemberObj['memberEmail'] = memberEmail.toLowerCase();
            newMemberObj['projectRole'] = projectRole;
            newMemberObj['creator'] = false;
            newMemberObj['accepted'] = false;

            newMemberArr.push(newMemberObj);

            var config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
                }
            };

            var data = {
                projectCollaborators: newMemberArr
            };

            var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/collaborators/' + id;

            setSaving(true)
            axios.put(api_url, data, config)
                .then(res => {
                    var newItemArr = [];

                    newItemArr.push(...res.data.data.projectCollaborators);
                    setList(newItemArr.concat(newMemberArr));
                    setCollaboratorsModal(false);
                    setMemberEmail('');
                    setProjectRole('');
                    setSaving(false)
                })
                .catch(error => {
                    setSaving(false)
                    if (error.response) {
                        if(error.response.status === 401 || error.response.status === 403) {
                            props.history.push('/login');
                        }
                        else {
                            setError(error.response.data.error[0]);
                        }
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        }
        else {
            setError('Email has already been added');
        }

    }

    function removeCollaborator() {
        var newCollaboratorArr = [];

        newCollaboratorArr = list.filter(item => { return item._id !== removeId });

        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
            }
        };

        var data = {
            projectCollaborators: newCollaboratorArr
        };

        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/collaborators/remove/' + id;

        axios.put(api_url, data, config)
            .then(res => {

                setConfirmModal(false);
                setList(newCollaboratorArr);
            })
            .catch(error => {
                if (error.response) {
                    if(error.response.status === 401 || error.response.status === 403) {
                        props.history.push('/login');
                    }
                    else {
                        setError(error.response.data.error[0]);
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });

    }

    return (
        <>
            <div className="page-content">
                <DashboardNavigation projectTitle={"Collaborators"} />
                <div className={"container-fluid px-3"}>
                    <Row>
                <Col sm="12">
                    <Row>
                        <Col xl={6} md={6} xs={12}>
                            <h4 className="card-title">Project Collaborators</h4>
                            <p className="card-title-desc">
                                Add and Remove team members on project
                            </p>
                        </Col>
                        <Col xl={6} md={6} xs={12} style={{ textAlign: 'right' }}>
                            <Button
                                type="button"
                                className={"add-new"}
                                onClick={() => {
                                    tog_collaboratorsModal();
                                }}>
                                + Add New
                            </Button>
                        </Col>
                    </Row>
                    <Card className={"mt-3"}>

                        <CardBody className={"p-0"}>
                            <Row>
                                {
                                    listError || list.length === 0 ? (
                                        <Col xs={12} xl={12} className="text-center">
                                            <p>No collaborators</p>
                                        </Col>
                                    ) : (
                                        <>
                                            {map(list, (user, key) => (
                                                <Col xl="3" sm="6" key={key}>
                                                    <Card className="text-center">
                                                        <CardBody>
                                                            <UncontrolledDropdown className="dropdown text-end">
                                                                <DropdownToggle className="text-muted font-size-16" tag="a">
                                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                                </DropdownToggle>

                                                                {
                                                                    ((JSON.parse(localStorage.getItem('authUser')).user.email === user.memberEmail && user.creator === true)) ? (
                                                                        null
                                                                    ) : (
                                                                        <>
                                                                            <DropdownMenu className="dropdown-menu-end">
                                                                                <DropdownItem
                                                                                    to="#"
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        tog_confirmModal();
                                                                                        setRemoveId(user._id);
                                                                                    }}>Remove</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </>
                                                                    )
                                                                }

                                                            </UncontrolledDropdown>
                                                            {!user.img ? (
                                                                <div className="avatar-xl mx-auto mb-4">
                                  <span
                                      className={
                                          "avatar-title bg-soft-light text-light display-4 m-0 rounded-circle"
                                      }
                                  >
                                    <i className="bx bxs-user-circle"></i>
                                  </span>
                                                                </div>
                                                            ) : (
                                                                <div className="mx-auto mb-4">
                                                                    <img
                                                                        className="avatar-xl rounded-circle img-thumbnail"
                                                                        src={user.img}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            )}

                                                            <h5 className="font-size-15 mb-1">
                                                                <Link to="#" className="text-dark">
                                                                    {user.memberEmail}
                                                                </Link>
                                                            </h5>
                                                            <p className="text-muted mb-2">{user.projectRole}</p>
                                                            <p className="text-muted mb-2"><i>{user.accepted ? "Accepted" : "Pending"}</i></p>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </>
                                    )
                                }
                            </Row>

                            <Modal
                                isOpen={collaboratorsModal}
                                toggle={() => {
                                    tog_collaboratorsModal();
                                }}
                            >
                                <Form onSubmit={e => addCollaborator(e)}>
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0" id="myModalLabel">
                                            Adding Team Member
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setCollaboratorsModal(false);
                                            }}
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <Label htmlFor="memberEmail" className="form-Label">Email</Label>
                                            <Input
                                                className="form-control"
                                                type="email"
                                                placeholder="gladys@jengaapp.com"
                                                defaultValue={memberEmail}
                                                onChange={e => setMemberEmail(e.target.value)}
                                                id="memberEmail"
                                                required />
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="role" className="form-Label">Role</Label>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder="Project Manager"
                                                defaultValue={projectRole}
                                                onChange={e => setProjectRole(e.target.value)}
                                                id="role"
                                                required />
                                            {/* <select
                            className="form-select"
                            onChange={e => setProjectRole(e.target.value)}
                            defaultValue={projectRole}
                            required
                            >
                              <option value="">Select</option>
                              <option value="employee">Employee</option>
                              <option value="consultant">Consultant</option>
                          </select> */}
                                        </div>

                                        <div className="mb-3">
                                            {error ? <Alert color="danger">{error}</Alert> : null}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                tog_collaboratorsModal();
                                            }}
                                            className="btn btn-secondary "
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit"
                                                className="btn btn-primary "
                                                disabled={isSaving}
                                        >
                                            {
                                                isSaving ?  <LoadingIndicator /> : <span>Add</span>
                                            }
                                        </button>
                                    </div>
                                </Form>
                            </Modal>

                            <Modal
                                isOpen={confirmModal}
                                toggle={() => {
                                    tog_confirmModal();
                                }}
                            >
                                <div className="modal-body">
                                    Are you sure you want to remove this collaborator?
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            tog_confirmModal();
                                        }}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                    <button type="button" className="btn btn-primary " onClick={() => removeCollaborator()}>
                                        Yes
                                    </button>
                                </div>
                            </Modal>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
                </div>
            </div>
        </>
    )
}

export default Collaborators
