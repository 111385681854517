import React, {useEffect} from "react"
import {Card, CardBody, Col, Progress} from "reactstrap";
import * as url from "../../../helpers/jengasmart_api_urls";
import axios from "axios";
import {useState} from "react";
import {useParams} from "react-router-dom";
import {Line, Pie} from "react-chartjs-2";
import FeatherIcon from "feather-icons-react";
export default function CostReport(props){
    const [project, setProject] = useState({});
    const [graphLoading, setGraphLoading] = useState(true);
    const[costBreakdownChart, setcostBreakdownChart] = useState([])
    const[chartPercentages, setChartPercentages] = useState([])
    const[maximumCostValue, setMaximumCostValue]=useState(0)
    const[maximumExpense, setMaximumExpense]= useState(0)
    const[minimumExpense, setMinimumExpense] = useState(0)
    const [varianceList, setVarianceList]=useState([])
    const [openTask, setOpenTask] = useState(null); // State to track the open task
    const [openSubTask, setOpenSubTask] = useState(null); // State to track the open sub task
    const [stats, setStats] = useState({
        totalMaterialAmount: 0,
        totalEquipmentAmount: 0,
        totalAdditionalCostsAmount: 0,
        totalLabourAmount: 0,
        totalExpenditure:0,
        totalBudget:0,
    });
    const {id} = useParams()
    var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/' + id;
    var stats_url = process.env.REACT_APP_API_BASE + url.GET_STATS + '/details/' + id;
    var variance_url = process.env.REACT_APP_API_BASE + url.GET_STATS + '/variance/' + id;
    var chat_data_url = process.env.REACT_APP_API_BASE + url.GET_STATS + '/charts/' + id;
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getProjectDetails = async ()=>{
        const projectDetails = await axios.get(api_url, config)
        setProject(projectDetails.data.data)
    }
    const getActualvsEstimated = async ()=>{
        const actualvsestimated = await axios.get(variance_url, config)
        setVarianceList(actualvsestimated.data.varianceData)
    }
    const toggleTask = (taskId) => {
        setOpenTask(openTask === taskId ? null : taskId); // Toggle the open task
    };
    const toggleSubTask = (subTaskId) => {
        setOpenSubTask(openSubTask === subTaskId ? null : subTaskId); // Toggle the open task
    };
    const getStats = async () => {
        try {
            const statValues = await axios.get(stats_url, config)

            const chartData = await axios.get(chat_data_url, config)
            console.log(chartData, "chartvalues")
            setChartPercentages(statValues.data)
            const { totalMaterialAmount, totalEquipmentAmount, totalLabourAmount, totalAdditionalCostsAmount, totalSubContractorCostAmount } = statValues.data;
            const {totalExpenditure, totalBudget, totalMaterialAmountsByMonth, totalEquipmentAmountsByMonth, totalLabourAmountsByMonth, totalLogAmountsByMonth} = chartData.data
            setStats({ totalMaterialAmount, totalEquipmentAmount, totalLabourAmount, totalAdditionalCostsAmount, totalExpenditure, totalBudget, totalSubContractorCostAmount });
            setcostBreakdownChart(chartData.data)
            const maxMaterialAmount = Math.max(...totalMaterialAmountsByMonth);
            const maxEquipmentAmount = Math.max(...totalEquipmentAmountsByMonth);
            const maxLabourAmount = Math.max(...totalLabourAmountsByMonth);
            const maxExpense = Math.max(...totalLogAmountsByMonth)
            const minExpense = Math.min(...totalLogAmountsByMonth)
            setMaximumExpense(maxExpense)
            setMinimumExpense(minExpense)
            const overallMax = Math.max(maxMaterialAmount, maxEquipmentAmount, maxLabourAmount);
            setMaximumCostValue(overallMax)
            setGraphLoading(false)
        } catch (e){

        }


    }
    const chartOptions = {
        legend: {
            display: false,
        },

    }
    const totalBudgetChart = {
        labels: ["Materials", "Equipments", "Labour", "Subcontractor", "Misc"],
        datasets: [
            {
                data: [chartPercentages.materialPercentage, chartPercentages.equipmentPercentage, chartPercentages.labourPercentage,chartPercentages.subContractorCostsPercentage, chartPercentages.additionalCostsPercentage, ],
                backgroundColor: ["#F2D8A7", "#918264", "#C2AD86", "#C3FFA7FF", "#D3FFA7FF"],
            },
        ],
    }

    const costBreakdownData = {
        type:'line',
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],
        datasets: [
            {
                label: "Total Material",
                fill: true,
                lineTension: 0,
                backgroundColor: "#ffffff",
                borderColor: "#556ee6",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#556ee6",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#556ee6",
                pointHoverBorderColor: "#fff",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: costBreakdownChart && costBreakdownChart.totalMaterialAmountsByMonth,
            },
            {
                label: "Total Equipment",
                fill: true,
                lineTension: 0,
                borderColor: "#F2D8A7",
                backgroundColor: "#ffffff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#ebeff2",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#ebeff2",
                pointHoverBorderColor: "#eef0f2",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: costBreakdownChart && costBreakdownChart.totalEquipmentAmountsByMonth,
            },
            {
                label: "Total Labour",
                fill: true,
                lineTension: 0,
                borderColor: "#91B7D9",
                backgroundColor: "#ffffff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#ebeff2",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#ebeff2",
                pointHoverBorderColor: "#eef0f2",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: costBreakdownChart && costBreakdownChart.totalLabourAmountsByMonth,
            },
            {
                label: "Subcontractor",
                fill: true,
                lineTension: 0,
                borderColor: "#d931ff",
                backgroundColor: "#ffffff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#ebeff2",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#ebeff2",
                pointHoverBorderColor: "#eef0f2",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: costBreakdownChart && costBreakdownChart.totalSubContractorCostsAmountsByMonth,
            },
            {
                label: "Misc",
                fill: true,
                lineTension: 0,
                borderColor: "#91d992",
                backgroundColor: "#ffffff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#ebeff2",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#ebeff2",
                pointHoverBorderColor: "#eef0f2",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: costBreakdownChart && costBreakdownChart.totalAdditionalCostsAmountsByMonth,
            },

        ],

    }
    var option = {

        scales: {
            yAxes: [
                {
                    ticks: {
                        max: maximumCostValue,
                        stepSize: maximumCostValue / 10,
                        beginAtZero: false,
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    }
                },

            ],
            xAxes: [{
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                }
            }],
        },

    }

    const actualExpensesData = {
        type:'line',
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],
        datasets: [
            {
                label: "Actual Expenses",
                fill: true,
                lineTension: 0.5,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 250);
                    gradient.addColorStop(0, "#194973");
                    gradient.addColorStop(1, "#19497300");
                    return gradient;
                },
                borderColor: "#194973",
                data: costBreakdownChart && costBreakdownChart.totalLogAmountsByMonth,
            },
        ],

    }
    var actualExpensesOption = {
        legend: {
            labels:{

            }
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        max: maximumExpense,
                        min: minimumExpense,
                        stepSize:maximumExpense /10,
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    }
                },

            ],
            xAxes: [{
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                }
            }],
        },

    }
    useEffect(()=>{
        getProjectDetails()
        getStats()
        getActualvsEstimated()

    }, [])
    return (
        <>
            <div className={"container-fluid px-3"}>
               <div className="d-flex flex-row flex-md-wrap w-100 gap-2 mb-3">
                   <Col className="p-3 rounded-3 border flex-grow-1">
                       <h6 className="stats-text">
                           Total BOQ Budget
                       </h6>
                       <p className="stats-value fs-5 mb-0">
                           {project.currency} {stats.totalBudget.toLocaleString()}
                       </p>
                   </Col>
                   <Col className="p-3 rounded-3 border flex-grow-1">
                       <h6 className="stats-text">
                           Expenditure
                       </h6>
                       <p className="stats-value fs-5 mb-0">
                           {project.currency} {stats.totalExpenditure.toLocaleString()}
                       </p>
                   </Col>
                   <Col className="p-3 rounded-3 border flex-grow-1">
                       <h6 className="stats-text">
                           Remaining Budget
                       </h6>
                       <p className="stats-value fs-5 mb-0">
                           {project.currency} {(stats.totalBudget - stats.totalExpenditure).toLocaleString()}
                       </p>
                   </Col>
                   <Col className="p-3 rounded-3 border flex-grow-1">
                       <h6 className="stats-text">
                           Percentage of Budget Utilized
                       </h6>
                       <p className="stats-value fs-5 mb-0">
                           {((stats.totalExpenditure / stats.totalBudget) * 100).toLocaleString(undefined, {
                               minimumFractionDigits: 2,
                               maximumFractionDigits: 2,
                           })}%
                       </p>
                   </Col>
               </div>
                <Progress
                    value={project.progress}
                    className="progress-lg"
                    style={{
                        height: "22px",
                        backgroundColor: "#0000001F",
                        borderRadius: "24px",
                    }}
                >
                    {project.progress}% Complete
                </Progress>
                <div className={"d-flex flex-row w-100 gap-2 mb-3"}>
                    <Col xs="12" md="6" className="p-3 rounded-3 flex-grow-1 ">
                        <Card className="p-3 rounded-3 border w-100  mb-0 h-100">
                            <h6 className="primary-text-color fw-bold">
                                Total Budget
                            </h6>
                        <CardBody className="p-1 d-flex align-items-center">

                            <div className="w-100">
                                {graphLoading ? (
                                    <div className="text-center mt-5 mb-5">
                                        <p className="placeholder-glow">
                                            <span
                                                className="placeholder col-4"
                                                style={{
                                                    width: 150,
                                                    height: 150,
                                                    borderRadius: "50%",
                                                }}
                                            ></span>
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <div className={"row"}>
                                            <div className={"col-md-6"}>
                                                <Pie width={251} height={251} data={totalBudgetChart} options={chartOptions} />
                                            </div>
                                            <div className={"col-md-6 d-flex align-items-center"}>
                                               <div className={"w-100"}>
                                                   <div className={"chart-legend-item w-100 d-flex align-items-center"}>
                                                       <div className={"container "}>
                                                           <div className={"row d-flex justify-content-between align-items-center"}>
                                                               <div className={"col-md-2"}>
                                                                   <div className={"chart-color"} style={{backgroundColor:"#F2D8A7"}}>

                                                                   </div>
                                                               </div>
                                                               <div className={"col-md-6"}>
                                                                   <div className={"chart-item-name"}>Materials</div>
                                                               </div>
                                                               <div className={"col-md-4"}>
                                                                   {graphLoading ? <> </> : <>
                                                                       <div className={"chart-item-value"}>{chartPercentages.materialPercentage ?  chartPercentages.materialPercentage.toLocaleString(undefined, {
                                                                           minimumFractionDigits: 2,
                                                                           maximumFractionDigits: 2,
                                                                       }) : 0}%</div>
                                                                   </>}

                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                                   <div className={"chart-legend-item w-100 d-flex align-items-center mt-2"}>
                                                       <div className={"container "}>
                                                           <div className={"row d-flex justify-content-between align-items-center"}>
                                                               <div className={"col-md-2"}>
                                                                   <div className={"chart-color"} style={{backgroundColor:"#918264"}}>

                                                                   </div>
                                                               </div>
                                                               <div className={"col-md-6"}>
                                                                   <div className={"chart-item-name"}>Equipment</div>
                                                               </div>
                                                               <div className={"col-md-4"}>
                                                                   {graphLoading ? <> </> : <>
                                                                       <div className={"chart-item-value"}>{chartPercentages.equipmentPercentage ? chartPercentages.equipmentPercentage.toLocaleString(undefined, {
                                                                           minimumFractionDigits: 2,
                                                                           maximumFractionDigits: 2,
                                                                       }) : 0}%</div>
                                                                   </>}

                                                               </div>
                                                           </div>
                                                       </div>

                                                   </div>
                                                   <div className={"chart-legend-item w-100 d-flex align-items-center mt-2"}>
                                                       <div className={"container "}>
                                                           <div className={"row d-flex justify-content-between align-items-center"}>
                                                               <div className={"col-md-2"}>
                                                                   <div className={"chart-color"}  style={{backgroundColor:"#C2AD86"}}>

                                                                   </div>
                                                               </div>
                                                               <div className={"col-md-6"}>
                                                                   <div className={"chart-item-name"}>Labour</div>
                                                               </div>
                                                               <div className={"col-md-4"}>
                                                                   {graphLoading ? <> </> : <>
                                                                       <div className={"chart-item-value"}>{chartPercentages.labourPercentage ? chartPercentages.labourPercentage.toLocaleString(undefined, {
                                                                       minimumFractionDigits: 2,
                                                                       maximumFractionDigits: 2,
                                                                   }): 0}%</div>
                                                                   </>}

                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                                   <div className={"chart-legend-item w-100 d-flex align-items-center mt-2"}>
                                                       <div className={"container "}>
                                                           <div className={"row d-flex justify-content-between align-items-center"}>
                                                               <div className={"col-md-2"}>
                                                                   <div className={"chart-color"}  style={{backgroundColor:"#C3FFA7FF"}}>

                                                                   </div>
                                                               </div>
                                                               <div className={"col-md-6"}>
                                                                   <div className={"chart-item-name"}>Subcontractor</div>
                                                               </div>
                                                               <div className={"col-md-4"}>
                                                                   {graphLoading ? <> </> : <>
                                                                       <div className={"chart-item-value"}>{chartPercentages.subContractorCostsPercentage ? chartPercentages.subContractorCostsPercentage.toLocaleString(undefined, {
                                                                       minimumFractionDigits: 2,
                                                                       maximumFractionDigits: 2,
                                                                   }): 0}%</div>
                                                                   </>}

                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                                   <div className={"chart-legend-item w-100 d-flex align-items-center mt-2"}>
                                                       <div className={"container "}>
                                                           <div className={"row d-flex justify-content-between align-items-center"}>
                                                               <div className={"col-md-2"}>
                                                                   <div className={"chart-color"}  style={{backgroundColor:"#F5E0B9"}}>

                                                                   </div>
                                                               </div>
                                                               <div className={"col-md-6"}>
                                                                   <div className={"chart-item-name"}>Misc</div>
                                                               </div>
                                                               <div className={"col-md-4"}>
                                                                   {graphLoading ? <> </> : <>
                                                                       <div className={"chart-item-value"}>{chartPercentages.additionalCostsPercentage ? chartPercentages.additionalCostsPercentage.toLocaleString(undefined, {
                                                                           minimumFractionDigits: 2,
                                                                           maximumFractionDigits: 2,
                                                                       }): 0}%</div>
                                                                   </>}

                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                            </div>
                                        </div>

                                    </>
                                )}
                            </div>

                        </CardBody>
                    </Card>
                    </Col>
                    <Col xs="12" md="6" className="p-3 rounded-3 flex-grow-1 ">
                        <Card className="p-3 rounded-3 border w-100  mb-0 h-100">
                            <CardBody className="p-1">
                                <h6 className="primary-text-color fw-bold">
                                    Actual Expenses
                                </h6>
                                <div className="w-100">
                                    {graphLoading ? (
                                        <div className="text-center mt-5 mb-5">
                                            <p className="placeholder-glow">
                                            <span
                                                className="placeholder col-4"
                                                style={{
                                                    width: 150,
                                                    height: 150,
                                                    borderRadius: "50%",
                                                }}
                                            ></span>
                                            </p>
                                        </div>
                                    ) : (
                                        <>
                                            <Line id='canvas' width={474} height={300} data={actualExpensesData} options={actualExpensesOption} />
                                        </>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
                <div className={"d-flex flex-row flex-md-wrap w-100 gap-2 mb-3"}>
                    <Col className="p-3 rounded-3 flex-grow-1">
                        <Card className="p-3 rounded-3 border w-100  mb-0">
                            <CardBody className="p-1">
                                <h6 className="primary-text-color fw-bold mb-3">
                                   Cost Breakdown
                                </h6>
                                <div className="w-100">
                                    {graphLoading ? (
                                        <div className="text-center mt-5 mb-5">
                                            <p className="placeholder-glow">
                                            <span
                                                className="placeholder col-4"
                                                style={{
                                                    width: 150,
                                                    height: 150,
                                                    borderRadius: "50%",
                                                }}
                                            ></span>
                                            </p>
                                        </div>
                                    ) : (
                                        <>
                                            <Line width={474} height={300} data={costBreakdownData} options={option} />
                                        </>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="p-3 rounded-3 flex-grow-1">
                        <Card className="p-3 rounded-3 border w-100 h-100 mb-0">
                            <CardBody className="p-1">
                        <h6 className="primary-text-color fw-bold">
                            Inventory Cost Breakdown
                        </h6>
                        <table className={"table inventory-cost mt-3"}>
                            <thead>
                                <tr>
                                    <th>Category</th>

                                    <th>Actual Cost</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span style={{ background: "#194973", display: 'inline-block', width: 10, height: 10, borderRadius: 50, marginRight: 10 }}></span> Material</td>

                                    <td>{stats.totalMaterialAmount.toLocaleString()}</td>

                                </tr>
                                <tr>
                                    <td><span style={{ background: "#556ee6", display: 'inline-block', width: 10, height: 10, borderRadius: 50, marginRight: 10 }}></span> Labour</td>

                                    <td>{stats.totalLabourAmount ? stats.totalLabourAmount.toLocaleString() : 0}</td>

                                </tr>
                                <tr>
                                    <td><span style={{ background: "#F2D8A7", display: 'inline-block', width: 10, height: 10, borderRadius: 50, marginRight: 10 }}></span> Equipment</td>

                                    <td>{stats.totalEquipmentAmount ?  stats.totalEquipmentAmount.toLocaleString() : 0}</td>

                                </tr>
                                <tr>
                                    <td><span style={{ background: "#C3FFA7FF", display: 'inline-block', width: 10, height: 10, borderRadius: 50, marginRight: 10 }}></span>Subcontractor</td>

                                    <td>{stats.totalSubContractorCostAmount ?  stats.totalSubContractorCostAmount.toLocaleString() : 0}</td>

                                </tr>
                                <tr>
                                    <td><span style={{ background: "#ebeff2", display: 'inline-block', width: 10, height: 10, borderRadius: 50, marginRight: 10 }}></span> Misc.</td>

                                    <td>{stats?.totalAdditionalCostsAmount?.toLocaleString()}</td>

                                </tr>
                            </tbody>
                        </table>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            <div className={"container-fluid"}>
                <h6 className="primary-text-color fw-bold">
                   Actual Vs. Estimated Costs, Based on BOQ
                </h6>
                <table className={"table task-progress mt-3 border"}>
                    <thead>
                    <tr>
                        <th>Task</th>
                        <th>BOQ Cost</th>
                        <th>Expenditure</th>
                        <th>Variance</th>
                        <th>% of Budget Used</th>
                    </tr>
                    </thead>
                    <tbody>
                    {varianceList.length > 0 ? (
                        varianceList.map((task, key) => (
                            <React.Fragment key={task.taskUniqueIdentifier}>
                                <tr onClick={() => toggleTask(task.taskUniqueIdentifier)}>
                                    <td> {openTask === task.taskUniqueIdentifier ? <>
                                        <FeatherIcon
                                            icon="chevron-right"
                                            className={"table-icon"}
                                        />
                                    </> : <>
                                        <FeatherIcon
                                            icon="chevron-down"
                                            className={"table-icon"}
                                        />
                                    </>}{task.taskName}</td>
                                    <td>{task.boqAmount ? task.boqAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) : 0}</td>
                                    <td>{task.expenditure ? task.expenditure.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) :  0}</td>
                                    <td style={{ color: task.variance < 0 ? 'red' : task.variance > 0 ? 'green' : 'blue' }}>{task.variance ? task.variance.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) : 0}</td>
                                    <td style={{ color: task.percentageVariance < 0 ? 'red' : task.percentageVariance > 0 ? 'green' : 'blue' }}>
                                        {task.percentageVariance != null   ? (Math.abs(task.percentageVariance.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }))) : 0} %
                                    </td>
                                </tr>
                                {task.subtasks && task.subtasks.length > 0 && (
                                    task.subtasks.map((subtask, index) => (
                                        <>
                                            <tr onClick={()=>toggleSubTask(subtask.taskUniqueIdentifier)}  key={`${task.taskUniqueIdentifier}-${index}`} className={"subtask"}>
                                                <td> {subtask.subtasks.length > 0 && <>
                                                    {openSubTask === subtask.taskUniqueIdentifier ? <>
                                                        <FeatherIcon
                                                            icon="chevron-right"
                                                            className={"table-icon"}
                                                        />
                                                    </> : <>
                                                        <FeatherIcon
                                                            icon="chevron-down"
                                                            className={"table-icon"}
                                                        />
                                                    </>}
                                                </>}{subtask.taskName}</td>
                                                <td>{subtask.boqAmount ? subtask.boqAmount.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) : 0}</td>
                                                <td>{subtask.expenditure ? subtask.expenditure.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) :  0}</td>
                                                <td style={{ color: subtask.variance < 0 ? 'red' : subtask.variance > 0 ? 'green' : 'blue' }}>{subtask.variance ? subtask.variance.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }) : 0}</td>
                                                <td style={{ color: subtask.percentageVariance < 0 ? 'red' : subtask.percentageVariance > 0 ? 'green' : 'blue' }}>
                                                    {subtask.percentageVariance != null   ? (Math.abs(subtask.percentageVariance.toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }))) : 0} %
                                                </td>
                                            </tr>
                                            {
                                                subtask.subtasks && subtask.subtasks.length > 0 && (
                                                    subtask.subtasks.map((subtask1, subtask1index)=>(
                                                        <>
                                                            <tr key={`${subtask1.taskUniqueIdentifier}-${subtask1index}`} className={"subtask1"}>
                                                                <td>{subtask1.taskName}</td>
                                                                <td>{subtask1.boqAmount ? subtask1.boqAmount.toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }) : 0}</td>
                                                                <td>{subtask1.expenditure ? subtask1.expenditure.toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }) :  0}</td>
                                                                <td style={{ color: subtask1.variance < 0 ? 'red' : subtask1.variance > 0 ? 'green' : 'blue' }}>{subtask1.variance ? subtask1.variance.toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }) : 0}</td>
                                                                <td style={{ color: subtask1.percentageVariance < 0 ? 'red' : subtask1.percentageVariance > 0 ? 'green' : 'blue' }}>
                                                                    {subtask1.percentageVariance != null ? (Math.abs(subtask1.percentageVariance.toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }))) : 0} %
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))
                                                )
                                            }
                                        </>
                                    ))
                                )}

                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={5}>No tasks completed</td>
                        </tr>
                    )}
                    </tbody>

                </table>
            </div>
            </div>
        </>
    )
}