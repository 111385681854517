export const projectNewData = [
  // {
  //   taskUniqueIdentifier: "1",
  //   taskName: "Parent Task 1",
  //   startDate: "2023-10-09T00:00:00.000Z",
  //   endDate: "2023-10-14T00:00:00.000Z",
  //   status: "Delayed",
  //   predecessor: "",
  // },
  // {
  //   taskUniqueIdentifier: "3",
  //   taskName: "Parent task 2",
  //   startDate: new Date("2023-10-12T00:00:00.000Z"),
  //   endDate: new Date("2023-10-25T00:00:00.000Z"),
  //   status: "On Track",
  //   predecessor: "",
  //   subtasks: [
  //     {
  //       taskUniqueIdentifier: "4",
  //       taskName: "sub task 2",
  //       startDate: new Date("2023-10-14T00:00:00.000Z"),
  //       endDate: new Date("2023-10-17T00:00:00.000Z"),
  //       status: "On Track",
  //       predecessor: "",
  //       subtasks: [
  //         {
  //           taskUniqueIdentifier: "5",
  //           taskName: "task 2",
  //           startDate: new Date("2023-10-15T00:00:00.000Z"),
  //           endDate: new Date("2023-10-18T00:00:00.000Z"),
  //           status: "On Track",
  //           predecessor: "",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   taskUniqueIdentifier: "2",
  //   taskName: "Milestone 1",
  //   startDate: "2023-10-30T00:00:00.000Z",
  //   duration: 0,
  // },
  {
    taskUniqueIdentifier: "1",
    taskName: "Parent Task 1",
    startDate: new Date("10/01/2023"),
    endDate: new Date("10/21/2023"),
    assignee: "1",
    status: "On Track",
    dependency: "",
    subtasks: [
      {
        taskUniqueIdentifier: "2",
        taskName: "Sub task 1",
        startDate: new Date("10/05/2023"),
        endDate: new Date("10/15/2023"),
        status: "On Track",
        subtasks: [
           {
            taskUniqueIdentifier: "6",
            taskName: "task 1",
            startDate: new Date("2023-10-15T00:00:00.000Z"),
            endDate: new Date("2023-10-18T00:00:00.000Z"),
            status: "On Track",
           }
        ]
      },
    ],
  },
  {
    taskUniqueIdentifier: "5",
    taskName: "Milestone 1",
    startDate: new Date("10/22/2023"),
    duration: 0,
    dependency: "1,2,6",
  },
  {
    taskUniqueIdentifier: "7",
    taskName: "Parent Task 1",
    startDate: new Date("10/22/2023"),
    endDate: new Date("10/25/2023"),
    assignee: "1",
    status: "On Track",
    dependency: "",
    subtasks: [
      {
        taskUniqueIdentifier: "8",
        taskName: "Sub task 1",
        startDate: new Date("10/23/2023"),
        endDate: new Date("10/24/2023"),
        status: "On Track",
        subtasks: [
           {
            taskUniqueIdentifier: "9",
            taskName: "task 1",
            startDate: new Date("10/24/2023"),
            endDate: new Date("10/25/2023"),
            status: "On Track",
           }
        ]
      },
    ],
  },
  {
    taskUniqueIdentifier: "10",
    taskName: "Milestone 1",
    startDate: new Date("10/25/2023"),
    duration: 0,
    dependency: "7,8,9",
  },
];

export const jengaData = [
   {
    taskUniqueIdentifier: "AB123",
    taskName: "Milestone 1",
    startDate: "31-10-2023",
    duration: 0,
    // predecessor: "",
    // duration: 0
   }
]

export const assigneeImages = [
    // {resourceId: "1" , resourceName: "WD"},
    // {resourceId: "2" , resourceName: "KW"},
    // {resourceId: "3" , resourceName: "KS"},
    // {resourceId: "4" , resourceName: "MU"},

]

export const mockData = [
  {
    id: 'hjghfy',
    taskName: 'Product concept',
    startDate: '2019-04-02T00:00:00.000Z',
    endDate: '2019-04-19T00:00:00.000Z',
    subtasks: [
        { id: 'hjghf4', taskName: 'Defining the product and its usage', startDate: '2019-04-02T00:00:00.000Z', endDate: '2019-04-05T00:00:00.000Z', status: 'Not started', assignee: 'Kwame' },
        { id: 'hjghf2', taskName: 'Defining target audience', startDate: new Date('04/02/2019'), endDate: new Date('04/05/2019')},
        {
          id: 'hjghfy7', taskName: 'Prepare product sketch and notes', startDate: new Date('04/08/2019'), endDate: new Date('04/19/2019'),
          dependency: 'hjghf2'
        },
    ]
},
{
  id: 'hjghf8', taskName: 'Concept approval', startDate: new Date('04/22/2019'), duration: 0, dependency: 'hjghfy7, hjghf2',
    Indicators: [
        {
            'date': '04/10/2019',
            'name': 'Design Phase',
            'tooltip': 'Design phase completed',
            'iconClass': 'okIcon e-icons'
        }
    ],
},
/* {
    TaskID: 6,
    TaskName: 'Market research',
    StartDate: new Date('04/02/2019'),
    EndDate: new Date('04/21/2019'),
    subtasks: [
        {
            TaskID: 7,
            TaskName: 'Demand analysis',
            StartDate: new Date('04/04/2019'),
            EndDate: new Date('04/21/2019'),
            subtasks: [
                {
                    TaskID: 8, TaskName: 'Customer strength', StartDate: new Date('04/04/2019'), Duration: 4,
                    Predecessor: '5', Progress: 30
                },
                { TaskID: 9, TaskName: 'Market opportunity analysis', StartDate: new Date('04/04/2019'), Duration: 4, Predecessor: '5' }
            ]
        },
        {
            TaskID: 10, TaskName: 'Competitor analysis', StartDate: new Date('04/04/2019'), Duration: 4,
            Predecessor: '7, 8', Progress: 30
        },
        { TaskID: 11, TaskName: 'Product strength analsysis', StartDate: new Date('04/04/2019'), Duration: 4, Predecessor: '9' },
        {
            TaskID: 12, TaskName: 'Research complete', StartDate: new Date('04/04/2019'), Duration: 0, Predecessor: '10',
            Indicators: [
                {
                    'date': '04/28/2019',
                    'name': 'Research completed',
                    'tooltip': 'Research completed',
                    'iconClass': 'description e-icons'
                }
            ],
        }
    ]
},
{
    TaskID: 13,
    TaskName: 'Product design and development',
    StartDate: new Date('04/04/2019'),
    EndDate: new Date('04/21/2019'),
    subtasks: [
        {
            TaskID: 14, TaskName: 'Functionality design', StartDate: new Date('04/04/2019'),
            Duration: 3, Progress: 30, Predecessor: '12'
        },
        { TaskID: 15, TaskName: 'Quality design', StartDate: new Date('04/04/2019'), Duration: 3, Predecessor: '12' },
        { TaskID: 16, TaskName: 'Define reliability', StartDate: new Date('04/04/2019'), Duration: 2, Progress: 30, Predecessor: '15' },
        { TaskID: 17, TaskName: 'Identifying raw materials', StartDate: new Date('04/04/2019'), Duration: 2, Predecessor: '15' },
        {
            TaskID: 18,
            TaskName: 'Define cost plan',
            StartDate: new Date('04/04/2019'),
            EndDate: new Date('04/21/2019'),
            subtasks: [
                {
                    TaskID: 19, TaskName: 'Manufacturing cost', StartDate: new Date('04/04/2019'),
                    Duration: 2, Progress: 30, Predecessor: '17'
                },
                { TaskID: 20, TaskName: 'Selling cost', StartDate: new Date('04/04/2019'), Duration: 2, Predecessor: '17' }
            ]
        },
        {
            TaskID: 21,
            TaskName: 'Development of the final design',
            StartDate: new Date('04/04/2019'),
            EndDate: new Date('04/21/2019'),
            subtasks: [
                {
                    TaskID: 22, TaskName: 'Defining dimensions and package volume', StartDate: new Date('04/04/2019'),
                    Duration: 2, Progress: 30, Predecessor: '19,20'
                },
                {
                    TaskID: 23, TaskName: 'Develop design to meet industry standards', StartDate: new Date('04/04/2019'),
                    Duration: 2, Predecessor: '22'
                },
                { TaskID: 24, TaskName: 'Include all the details', StartDate: new Date('04/04/2019'), Duration: 3, Predecessor: '23' }
            ]
        },
        {
            TaskID: 25, TaskName: 'CAD computer-aided design', StartDate: new Date('04/04/2019'),
            Duration: 3, Progress: 30, Predecessor: '24'
        },
        { TaskID: 26, TaskName: 'CAM computer-aided manufacturing', StartDate: new Date('04/04/2019'), Duration: 3, Predecessor: '25' },
        {
            TaskID: 27, TaskName: 'Design complete', StartDate: new Date('04/04/2019'), Duration: 0, Predecessor: '26',
        }
    ]
},
{ TaskID: 28, TaskName: 'Prototype testing', StartDate: new Date('04/04/2019'), Duration: 4, Progress: 30, Predecessor: '27' },
{ TaskID: 29, TaskName: 'Include feedback', StartDate: new Date('04/04/2019'), Duration: 4, Predecessor: '28ss', Indicators: [
        {
            'date': '05/24/2019',
            'name': 'Production phase',
            'tooltip': 'Production phase completed',
            'iconClass': 'okIcon e-icons'
        }
    ], },
{ TaskID: 30, TaskName: 'Manufacturing', StartDate: new Date('04/04/2019'), Duration: 5, Progress: 30, Predecessor: '28,29' },
{ TaskID: 31, TaskName: 'Assembling materials to finsihed goods', StartDate: new Date('04/04/2019'), Duration: 5, Predecessor: '30' },
{
    TaskID: 32,
    TaskName: 'Feedback and testing',
    StartDate: new Date('04/04/2019'),
    EndDate: new Date('04/21/2019'),
    subtasks: [
        {
            TaskID: 33, TaskName: 'Internal testing and feedback', StartDate: new Date('04/04/2019'),
            Duration: 3, Progress: 45, Predecessor: '31'
        },
        {
            TaskID: 34, TaskName: 'Customer testing and feedback', StartDate: new Date('04/04/2019'),
            Duration: 3, Progress: 50, Predecessor: '33'
        }
    ]
},
{
    TaskID: 35,
    TaskName: 'Final product development',
    StartDate: new Date('04/04/2019'),
    EndDate: new Date('04/21/2019'),
    subtasks: [
        {
            TaskID: 36, TaskName: 'Important improvements', StartDate: new Date('04/04/2019'),
            Duration: 4, Progress: 30, Predecessor: '34'
        },
        {
            TaskID: 37, TaskName: 'Address any unforeseen issues', StartDate: new Date('04/04/2019'),
            Duration: 4, Progress: 30, Predecessor: '36ss',
            Indicators: [
                {
                    'date': '06/21/2019',
                    'name': 'Sales and marketing',
                    'tooltip': 'Sales and marketing',
                    'iconClass': 'description e-icons'
                }
            ],
        }
    ]
},
{
    TaskID: 38,
    TaskName: 'Final product',
    StartDate: new Date('04/04/2019'),
    EndDate: new Date('04/21/2019'),
    subtasks: [
        { TaskID: 39, TaskName: 'Branding product', StartDate: new Date('04/04/2019'), Duration: 4, Predecessor: '37' },
        {
            TaskID: 40, TaskName: 'Marketing and presales', StartDate: new Date('04/04/2019'),
            Duration: 4, Progress: 30, Predecessor: '39'
        }
    ]
  } */
];


let taskMockData = [
    {
      taskUniqueIdentifier: 6,
      taskName: "Build 6 semi detached rooms",
      startDate: "2023-09-09T00:00:00.000Z",
      endDate: "2024-06-27T00:00:00.000Z",
      description: "Build two semi-detached rooms",
      projectId: "64ec5119a0794957980a8e27",
      userId: "64ec509aa0794957980a8e23",
      assignee: "64ec5119a0794957980a8e28",
      _id: "64fc46eb9f659f2b0c323508",
      subtasks: [
        {
          taskUniqueIdentifier: 7,
          taskName: "Demand analysis",
          startDate: "2023-09-09T00:00:00.000Z",
          endDate: "2024-12-19T00:00:00.000Z",
          subtasks: [
            {
              taskUniqueIdentifier: 8,
              taskName: "Customer strength",
              startDate: "2023-09-09T00:00:00.000Z",
              endDate: "2023-09-19T00:00:00.000Z",
              description: "Build two semi-detached rooms",
              projectId: "64ec5119a0794957980a8e27",
              userId: "64ec509aa0794957980a8e23",
              assignee: "64ec5119a0794957980a8e28",
            },
            {
              taskUniqueIdentifier: 9,
              taskName: "Market opportunity analysis",
              startDate: "2023-09-09T00:00:00.000Z",
              endDate: "2024-09-19T00:00:00.000Z",
              description: "Build two semi-detached rooms",
              projectId: "64ec5119a0794957980a8e27",
              userId: "64ec509aa0794957980a8e23",
              assignee: "64ec5119a0794957980a8e28",
            },
          ],
        },
        {
          taskUniqueIdentifier: 10,
          taskName: "Competitor analysis",
          startDate: "2023-09-09T00:00:00.000Z",
          endDate: "2023-09-19T00:00:00.000Z",
          description: "Build two semi-detached rooms",
          projectId: "64ec5119a0794957980a8e27",
          userId: "64ec509aa0794957980a8e23",
          assignee: "64ec5119a0794957980a8e28",
        },
        {
          taskUniqueIdentifier: 11,
          taskName: "Product strength analsysis",
          startDate: "2023-09-09T00:00:00.000Z",
          endDate: "2024-09-19T00:00:00.000Z",
          description: "Build two semi-detached rooms",
          projectId: "64ec5119a0794957980a8e27",
          userId: "64ec509aa0794957980a8e23",
          assignee: "64ec5119a0794957980a8e28",
        },
        {
          taskUniqueIdentifier: 12,
          taskName: "Research complete",
          startDate: "2023-09-09T00:00:00.000Z",
          endDate: "2023-09-29T00:00:00.000Z",
          description: "Build two semi-detached rooms",
          projectId: "64ec5119a0794957980a8e27",
          userId: "64ec509aa0794957980a8e23",
          assignee: "64ec5119a0794957980a8e28",
        },
      ],
    },
];


let taskSettings = {
    // id: "taskUniqueIdentifier",
    // name: "taskName",
    // startDate: "startDate",
    // endDate: "endDate",
    // assignee: "assignee",
    // duration: "Duration",
    // child: "subtasks",

    id: 'TaskID',
    name: 'TaskName',
    startDate: 'StartDate',
    endDate: 'EndDate',
    // assignee: 'assignee',
    duration: 'Duration',
    // milestone: 'Milestone',
    //progress: 'Progress',
    dependency: 'Predecessor',
    child: 'subtasks'
  };
