import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//import images
import logo from "../../assets/images/logo.svg";
import CarouselPage from './CarouselPage';

const CodeConfirmationSuccess = () => {
    //meta title
    document.title = "Code Confirmation Success | Jenga App";
    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col className="col-xxl-4 col-lg-4 col-md-4">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={logo} alt="" height="28" /> <span className="logo-txt"></span>
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <div className="avatar-lg mx-auto mb-3">
                                                    <div className="avatar-title rounded-circle bg-light">
                                                        <i className="bx bx-check h2 mb-0 text-primary"></i>
                                                    </div>
                                                </div>
                                                <h5 className="mb-0">Successfully verified</h5>
                                                <p className="text-muted mt-2">You can now log into your account using the button below</p>
                                            </div>
                                            <div className="mb-3 mt-4">
                                                <Link to="/login" className="btn btn-primary w-100"> Sign In </Link>
                                            </div>
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">© {new Date().getFullYear()} Jenga App</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CodeConfirmationSuccess;