// Login
export const POST_LOGIN_DETAILS = "auth"

// Register
export const POST_REGISTER_DETAILS = "users"

// Project
export const POST_PROJECT_DETAILS = "projects"

// Project
export const GET_STATS = "stats"

// Daily Logs
export const POST_LOG_DETAILS = "track-logs"

// Tasks
export const POST_TASKS_DETAILS = "tasks"

// Sub Tasks
export const POST_SUBTASKS_DETAILS = "subtasks"

// Parent Tasks
export const POST_PARENTTASKS_DETAILS = "parenttasks"

// Milestones
export const POST_MILESTONES_DETAILS = "milestones"

// Materials
export const POST_MATERIAL_DETAILS = "materials"

// Equipment
export const POST_EQUIPMENT_DETAILS = "equipments"

// Labour
export const POST_LABOUR_DETAILS = "labour"

// Labour
export const POST_SUBCONTRACTOR_DETAILS = "subcontractor"