export const taskStatus = [
    {
        label: "Not Started",
        value: "Not Started",
    },
    {
        label: "In Progress",
        value: "In Progress",
    },
    {
        label: "Completed",
        value: "Completed"
    }
]


    
    