import React from 'react'
import {useField} from '@formiz/core'

export const SelectTaskField = (props) => {
   const {setValue,value , errorMessage , isValid , isSubmitted , id } = useField(props)
   const {  required , placeholder , data , name , handleData } = props
   const [isTouched, setIsTouched] = React.useState(false)
   const showError = !isValid && (isTouched || isSubmitted)
   
   const handleOnchange = (e) => {
       setValue(e.target.value)
       handleData(e.target.value)
   }

   return (
       
    <div className="row">
      <div className="col-md-12">
        <div className="mb-3 mb-3">
            <select 
                className={`form-control ${(showError) ? 'is-invalid' : ''}`} 
                id={id} 
                value={value ?? ''}
               onChange={e => handleOnchange(e)}
               onBlur={() => setIsTouched(true)}
               aria-invalid={showError}
               aria-required={!!required}
               aria-describedby={showError ? `${id}-error` : null}
                >
                 <option value="">{placeholder}</option>
                {data.map((item, key) => (
                <option value={item._id} key={key}>{item.taskName}</option>
                ))}
            </select>
            
            
              {showError && (
               <div id={`${id}-error`} className="invalid-feedback d-block">
                  { errorMessage }
               </div>
               )}
        </div>
      </div>
    </div>
                
        
   )
}