import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal
} from "reactstrap";
import { some } from "lodash";

import axios from 'axios';
import * as url from "../../helpers/jengasmart_api_urls";


const Settings = props => {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const [projectName, setProjectName] = useState("")
  const [error, setError] = useState("")

  function tog_confirmDeleteModal() {
    setConfirmDeleteModal(!confirmDeleteModal);
  }

  function tog_errorModal() {
    setErrorModal(!errorModal);
  }
  
  const deleteConfirmation = () => {
    const authUser = JSON.parse(localStorage.getItem('authUser'));

    const findCollaboration = some(props.projectCollaborators, { 'memberEmail': authUser.user.email, 'creator': true });

    if(findCollaboration === true) {
      setConfirmDeleteModal(true);
    }
    else {
      setErrorModal(true);
    }
  }

  const deleteProject = () => {
    // e.preventDefault();

    if(projectName === "") {
      setError("Project Name cannot be empty");
    }
    else {
      if(projectName !== props.projectName) {
        setError("Input does not match the project name");
      }
      else {
        var config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
          }
        };
        
        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/' + props.projectId;

        axios.delete(api_url, config)
        .then(res => {
          if(res.data.success === true) {
            props.history.push('/projects');
          }
        })
        .catch(error => {
          if (error.response) {
              if(error.response.status === 401 || error.response.status === 403) {
                  props.history.push('/login');
              }
              else {
                  setError(error.response.data.error[0]);
              }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
        });
      }
    }
  }

  return (
    <Row>
        <Col lg="12">
          <h4 className="card-title mb-4" style={{ color: 'red' }}>Danger Zone</h4>

          <Card>
            <CardBody>
              <Row>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <p className="text-muted mt-2">Confirm and remove project</p>
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="text-center">
                    <Button type="button" color="danger" onClick={() => deleteConfirmation()}>
                      Delete
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Modal
            isOpen={confirmDeleteModal}
            toggle={() => {
              tog_confirmDeleteModal();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Confirm Deletion
              </h5>
              <button
                type="button"
                onClick={() => {
                  setConfirmDeleteModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <div className="mb-3">
                    <Label htmlFor="project-name" className="form-Label">Enter the project name ( <code>{props.projectName}</code> ) and click on confirm to delete this project</Label>
                    <Input 
                      className="form-control" 
                      type="text" 
                      placeholder="The Regular Construction"
                      defaultValue={projectName}
                      onChange={e => setProjectName(e.target.value)}
                      id="project-name"
                      required />
                </div>
                
                <div className="mb-3">
                  {error ? <Alert color="danger">{error}</Alert> : null}
                </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_confirmDeleteModal();
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={() => deleteProject()}>
                Confirm
              </button>
            </div>
          </Modal>


          <Modal
            isOpen={errorModal}
            toggle={() => {
              tog_errorModal();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Unauthorized
              </h5>
              <button
                type="button"
                onClick={() => {
                  setErrorModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <Alert color="danger">You currently do not have the required permissions to delete this project. Please contact the project administrator.</Alert>
              </div>
            </div>
            <div className="modal-footer">
              <button 
                type="button"
                onClick={() => {
                  tog_errorModal();
                }}
                className="btn btn-primary"
                data-dismiss="modal">
                Close
              </button>
            </div>
          </Modal>
        </Col>
    </Row>
  )
}

export default Settings;