import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Container, Input, Label, Form, FormFeedback  } from "reactstrap"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom"

// import images
import logo from "../../assets/images/logo.svg"
import CarouselPage from "../AuthenticationInner/CarouselPage"

import {LoadingIndicator} from '../../components/Common'


const Register = props => {
  const [showPassword, setShowPassword] = useState(false);

  //meta title
  document.title = "Register | Jenga App";

  const dispatch = useDispatch()

  const { user, registrationError , loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      // phone: '',
      // role: '',
      password: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your Firstname"),
      lastName: Yup.string().required("Please Enter Your Lastname"),
      email: Yup.string().email().required("Please Enter Your Email"),
      // phone: Yup.number().positive().integer().required("Please Enter Your Phone Number"),
      // role: Yup.string().required("Please Select Your Role"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values, props.history));
    }
  });

  useEffect(() => {
    dispatch(apiError(""))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={4} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" /> <span className="logo-txt"></span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Register Account</h5>
                        <p className="text-muted mt-2">Create your free Jenga App account now.</p>
                      </div>

                      <Form
                        className="needs-validation custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {user && user ? (
                          <Alert color="success">
                            Registered User Successfully
                          </Alert>
                        ) : null}

                        {registrationError && registrationError ? (
                          <Alert color="danger">{registrationError}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">First Name</Label>
                          <Input
                            name="firstName"
                            type="text"
                            placeholder="Enter first name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                              validation.touched.firstName && validation.errors.firstName ? true : false
                            }
                          />
                          {validation.touched.firstName && validation.errors.firstName ? (
                            <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Last Name</Label>
                          <Input
                            name="lastName"
                            type="text"
                            placeholder="Enter last name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                              validation.touched.lastName && validation.errors.lastName ? true : false
                            }
                          />
                          {validation.touched.lastName && validation.errors.lastName ? (
                            <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        {/* <div className="mb-3">
                          <Label className="form-label">Phone</Label>
                          <Input
                            id="phone"
                            name="phone"
                            className="form-control"
                            placeholder="Enter phone number (2547181231234)"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone && validation.errors.phone ? true : false
                            }
                          />
                          {validation.touched.phone && validation.errors.phone ? (
                            <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Role</Label>
                          <select 
                            className="form-select"
                            name="role"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.role || ""}
                            invalid={
                              validation.touched.role && validation.errors.role ? true : false
                            }>
                            <option value="">Select</option>
                            <option value="owner">Owner</option>
                            <option value="employee">Employee</option>
                            <option value="consultant">Consultant</option>
                          </select>
                          {validation.touched.role && validation.errors.role ? (
                            <FormFeedback type="invalid">{validation.errors.role}</FormFeedback>
                          ) : null}
                        </div> */}

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-4">
                          <div className="form-check" onClick={() => setShowPassword(!showPassword)}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="remember-check"
                              checked={showPassword}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="remember-check"
                            >
                              Show password
                            </label>
                          </div>
                        </div>
                        
                        <div className="mb-4">
                          <p className="mb-0">
                            By registering you agree to the Jenga App{" "}
                            <Link to="#" className="text-primary">
                              Terms and Conditions
                            </Link>
                          </p>
                        </div>
                        <div className="mb-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            disabled={loading}
                                >
                          {
                              loading ?  <LoadingIndicator /> : <span> Register</span>
                            } 
                           
                          </button>
                        </div>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">Already have an account ? <Link to="/login"
                          className="text-primary fw-semibold"> Login </Link> </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()} Jenga App</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register